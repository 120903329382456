import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { PhotoCarouselModalComponent } from '@smartflip/carousel';
import { DEFAULT_IMAGE_URL } from '@smartflip/data-constants';
import { SwiperPhoto } from '@smartflip/data-models';
import { UploadFormComponent } from './upload-form/upload-form.component';

@Component({
  selector: 'app-property-image',
  templateUrl: './property-image.component.html',
  styleUrls: ['./property-image.component.scss'],
})
export class PropertyImageComponent implements OnInit, OnChanges {
  @Input() imgUrl: string;
  @Input() useUpload: boolean = false; // turn off upload completely
  @Input() editable: boolean; // deprecate this? see logic in OnInit
  @Input() imgClass: string;
  @Input() initialSlideIndex: number = 0;
  @Input() propertyId: string;
  @Input() propertyPhotos: SwiperPhoto[] = [];
  @Input() disableFullscreen: boolean = false;

  @Output() onThumbnailChange: EventEmitter<string> = new EventEmitter();

  constructor(public platform: Platform, private modal: MatDialog) {}

  private setUpView() {
    const shouldNotReplace = this.propertyPhotos?.length > 1; // maybe also confirm that the image is the default

    this.imgUrl = this.imgUrl || DEFAULT_IMAGE_URL;
    this.propertyPhotos =
      this.propertyPhotos?.length > 0 && shouldNotReplace
        ? this.propertyPhotos
        : [{ url: this.imgUrl, alt: 'Home Photo' }];

    if (this.platform.is('cordova')) {
      this.editable = false;
    } else {
      this.editable = true;
    }
  }

  public usingDefaultImage() {
    return this.imgUrl === DEFAULT_IMAGE_URL;
  }

  uploadImage() {
    const modalRef = this.modal.open(UploadFormComponent, {
      data: {
        propertyId: this.propertyId,
        imgUrl: this.imgUrl,
      },
    });

    modalRef.afterClosed().subscribe(this.onImageChanged.bind(this));
  }

  public fullScreen() {
    const config = {
      panelClass: 'full-screen-modal',
      data: {
        photos: this.propertyPhotos,
      },
    };
    const modalRef = this.modal.open(PhotoCarouselModalComponent, config);

    modalRef.afterClosed().subscribe(this.onImageChanged.bind(this));
  }

  private onImageChanged(imgUrl: string) {
    if (imgUrl?.length > 0) {
      this.imgUrl = imgUrl || this.imgUrl;
      this.onThumbnailChange.next(imgUrl);
    }
  }

  ngOnInit(): void {
    this.setUpView();
  }

  ngOnChanges(): void {
    this.setUpView();
  }
}
