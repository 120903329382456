import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-repair-cost-row',
    templateUrl: './repair-cost-row.component.html',
    styleUrls: ['./repair-cost-row.component.scss']
})
export class RepairCostRowComponent implements OnInit{
  @Input() form: UntypedFormGroup;
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter();

  details: UntypedFormArray;

  removeCost(indexOfCost: number) {
      (this.details as UntypedFormArray).removeAt(indexOfCost);
  }

  handleSubmit(evt) {
      this.onSubmit.emit(true);
  }

  ngOnInit() {
      this.details = this.form.get('details') as UntypedFormArray;
  }
}
