<span
  (mouseenter)="showPopup = true"
  (mouseleave)="showPopup = false"
  (click)="showPopup = !showPopup"
>
  <mat-icon>info</mat-icon>
</span>

@if(showPopup) {
<div class="StatusLegend__Popup">
  <h2 class="mt-0">Legend</h2>
  @for (status of statuses; track $index) {
  <div class="mb-3 d-flex">
    <mat-icon [innerHTML]="status.value"></mat-icon>
    <div class="ml-2" [innerHTML]="status.key"></div>
  </div>
  }
</div>
}
