export const addressWordToAbbreviation: { [word: string]: string } = {
  alley: 'aly',
  annex: 'anx',
  arcade: 'arc',
  avenue: 'ave',
  bayoo: 'byu',
  beach: 'bch',
  bend: 'bnd',
  bluff: 'blf',
  bluffs: 'lfs',
  bottom: 'btm',
  boulevard: 'blvd',
  branch: 'br',
  bridge: 'brg',
  brook: 'brk',
  brooks: 'brks',
  burg: 'bg',
  burgs: 'bgs',
  bypass: 'byp',
  camp: 'cp',
  canyon: 'cyn',
  cape: 'cpe',
  causeway: 'cswy',
  center: 'ctr',
  centers: 'ctrs',
  circle: 'cir',
  circles: 'cirs',
  cliff: 'clf',
  cliffs: 'clfs',
  club: 'clb',
  common: 'cmn',
  corner: 'cor',
  corners: 'cors',
  course: 'crse',
  court: 'ct',
  courts: 'cts',
  cove: 'cv',
  coves: 'cvs',
  creek: 'crk',
  crescent: 'cres',
  crest: 'crst',
  crossing: 'xing',
  crossroad: 'xrd',
  curve: 'curv',
  dale: 'dl',
  dam: 'dm',
  divide: 'dv',
  drive: 'dr',
  drives: 'drs',
  estate: 'est',
  estates: 'ests',
  expressway: 'expy',
  extension: 'ext',
  extensions: 'exts',
  fall: 'fall',
  falls: 'fls',
  ferry: 'fry',
  field: 'fld',
  fields: 'flds',
  flat: 'flt',
  flats: 'flts',
  ford: 'frd',
  fords: 'frds',
  forest: 'frst',
  forge: 'frg',
  forges: 'frgs',
  fork: 'frk',
  forks: 'frks',
  fort: 'ft',
  freeway: 'fwy',
  garden: 'gdn',
  gardens: 'gdns',
  gateway: 'gtwy',
  glen: 'gln',
  glens: 'glns',
  green: 'grn',
  greens: 'grns',
  grove: 'grv',
  groves: 'grvs',
  harbor: 'hbr',
  harbors: 'hbrs',
  haven: 'hvn',
  heights: 'hts',
  highway: 'hwy',
  hill: 'hl',
  hills: 'hls',
  hollow: 'holw',
  inlet: 'inlt',
  interstate: 'i',
  island: 'is',
  islands: 'iss',
  isle: 'isle',
  junction: 'jct',
  junctions: 'jcts',
  key: 'ky',
  keys: 'kys',
  knoll: 'knl',
  knolls: 'knls',
  lake: 'lk',
  lakes: 'lks',
  land: 'land',
  landing: 'lndg',
  lane: 'ln',
  light: 'lgt',
  lights: 'lgts',
  loaf: 'lf',
  lock: 'lck',
  locks: 'lcks',
  lodge: 'ldg',
  loop: 'loop',
  mall: 'mall',
  manor: 'mnr',
  manors: 'mnrs',
  meadow: 'mdw',
  meadows: 'mdws',
  mews: 'mews',
  mill: 'ml',
  mills: 'mls',
  mission: 'msn',
  moorhead: 'mhd',
  motorway: 'mtwy',
  mount: 'mt',
  mountain: 'mtn',
  mountains: 'mtns',
  neck: 'nck',
  orchard: 'orch',
  oval: 'oval',
  overpass: 'opas',
  park: 'park',
  parks: 'park',
  parkway: 'pkwy',
  parkways: 'pkwy',
  pass: 'pass',
  passage: 'psge',
  path: 'path',
  pike: 'pike',
  pine: 'pne',
  pines: 'pnes',
  place: 'pl',
  plain: 'pln',
  plains: 'plns',
  plaza: 'plz',
  point: 'pt',
  points: 'pts',
  port: 'prt',
  ports: 'prts',
  prairie: 'pr',
  radial: 'radl',
  ramp: 'ramp',
  ranch: 'rnch',
  rapid: 'rpd',
  rapids: 'rpds',
  rest: 'rst',
  ridge: 'rdg',
  ridges: 'rdgs',
  river: 'riv',
  road: 'rd',
  roads: 'rds',
  route: 'rte',
  row: 'row',
  rue: 'rue',
  run: 'run',
  shoal: 'shl',
  shoals: 'shls',
  shore: 'shr',
  shores: 'shrs',
  skyway: 'skwy',
  spring: 'spg',
  springs: 'spgs',
  spur: 'spur',
  spurs: 'spur',
  square: 'sq',
  squares: 'sqs',
  station: 'sta',
  stream: 'strm',
  street: 'st',
  streets: 'sts',
  summit: 'smt',
  throughway: 'trwy',
  trace: 'trce',
  track: 'trak',
  trail: 'trl',
  tunnel: 'tunl',
  turnpike: 'tpke',
  underpass: 'upas',
  union: 'un',
  unions: 'uns',
  valley: 'vly',
  valleys: 'vlys',
  viaduct: 'via',
  view: 'vw',
  views: 'vws',
  village: 'vlg',
  villages: 'vlgs',
  ville: 'vl',
  vista: 'vis',
  walk: 'walk',
  walks: 'walk',
  wall: 'wall',
  way: 'way',
  ways: 'ways',
  well: 'wl',
  wells: 'wls',
  apartment: 'apt',
  basement: 'bsmt',
  building: 'bldg',
  department: 'dept',
  floor: 'fl',
  front: 'frnt',
  hangar: 'hngr',
  lobby: 'lbby',
  lot: 'lot',
  lower: 'lowr',
  office: 'ofc',
  penthouse: 'ph',
  pier: 'pier',
  rear: 'rear',
  room: 'rm',
  side: 'side',
  slip: 'slip',
  space: 'spc',
  stop: 'stop',
  suite: 'ste',
  trailer: 'trlr',
  unit: 'unit',
  upper: 'uppr',
};
