import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@smartflip/data-models';
import { FormService, RoiService } from '@smartflip/ui-utils';
import { summaryFields } from '@smartflip/data-field-configuration';

@Component({
  selector: 'app-cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.scss'],
})
export class CostDetailsComponent implements OnInit {
  @Input() propertyID: string;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  homeFieldList: FieldConfig[] = [];
  summaryForm: UntypedFormGroup;

  // Shorthand and available to the template
  objectKeys = Object.keys;

  constructor(public roi: RoiService, private fS: FormService) {}

  async init() {
    this.homeFieldList = { ...summaryFields };
    this.summaryForm = this.roi.formGroups.summary;
  }

  onSubmit(event: Event) {
    // TODO: Possibly delete this
    event.preventDefault();
    event.stopPropagation();
    if (this.summaryForm.valid) {
      this.submit.emit(this.summaryForm.value);
    } else {
      this.fS.validateAllFormFields(this.summaryForm);
    }
  }

  ngOnInit() {
    this.init();
  }
}
