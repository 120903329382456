<div class="RepairCostDetail">
  <header class="RepairCostDetail__Header">
    <h3>Repairs Total: {{ total | currency }}</h3>
  </header>
  <div class="RepairCostDetail__Container">
    <app-repair-cost-row [form]="repairDetailForm"></app-repair-cost-row>
  </div>
  <div class="RepairCostDetail__ButtonWrapper">
    <div class="d-flex flex-wrap">
      <div class="row p-2 w-100">
        <button mat-button mat-raised-button class="accent" (click)="addCost()">
          <mat-icon>add</mat-icon>
          <span>Add </span>
          <span *ngIf="repairDetailForm.get('details')?.value.length > 0"
            >another
          </span>
          <span>cost</span>
        </button>
      </div>
      <div class="row p d-flex w-100 justify-content-between">
        <div class="p-2">
          <button
            class="RepairCostDetail__Button"
            mat-button
            mat-raised-button
            (click)="close()"
          >
            Cancel
          </button>
        </div>
        <div class="p-2">
          <button
            class="RepairCostDetail__Button"
            mat-button
            mat-raised-button
            (click)="save()"
            class="accent"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
