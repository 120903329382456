import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { PhotoCarouselComponent } from './photo-carousel/photo-carousel.component';
import { PhotoCarouselModalComponent } from './photo-carousel-modal/photo-carousel-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [PhotoCarouselComponent, PhotoCarouselModalComponent],
  exports: [PhotoCarouselComponent, PhotoCarouselModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesPhotoCarouselModule {
  constructor() {
    register();
  }
}
