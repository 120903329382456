import { Component, ElementRef } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  field!: FieldConfig;
  group!: UntypedFormGroup;
  public formControl!: AbstractControl<any> | null;

  constructor(private el: ElementRef) {}

  public getFieldOptions(field: FieldConfig) {
    return field.options?.map((option) => option as string);
  }

  public scrollIntoView() {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
