export const mainPropertyView = [
  { name: 'address' }, // TODO: remove?
  { name: 'geoLocation' },
  { name: 'postalCode' }, // TODO: remove?
  { name: 'title' },
  { name: 'numBedroom' },
  { name: 'numBathroom' },
  {
    name: 'transactions',
    flatten: true,
    sub_fields: [
      { name: 'saleDate' },
      { name: 'buyerFirstName' },
      { name: 'buyerLastName' },
      { name: 'lenderName' },
      { name: 'contactOwnerMailAddressFull' },
      { name: 'loanAmount' },
      { name: 'loanAmount' },
      { name: 'mortgageTerm' },
    ],
  },
  { name: 'imageURLs' },
  { name: 'lotSizeValue' },
  { name: 'lotSizeUnit' },
  { name: 'floorSizeValue' },
  { name: 'floorSizeUnit' },
  { name: 'yearBuilt' },
  { name: 'propertyType' },
];

export const compPropertyView = [
  { name: 'address' },
  { name: 'geoLocation' },
  { name: 'city' },
  { name: 'province' },
  { name: 'postalCode' },
  { name: 'mostRecentPriceAmount' },
  { name: 'mostRecentPriceDate' },
  {
    name: 'transactions',
    flatten: true,
    sub_fields: [{ name: 'saleDate' }, { name: 'price' }],
  },
  { name: 'imageURLs' },
  { name: 'numBedroom' },
  { name: 'numBathroom' },
  { name: 'lotSizeValue' },
  { name: 'lotSizeUnit' },
  { name: 'floorSizeValue' },
  { name: 'floorSizeUnit' },
  { name: 'yearBuilt' },
  { name: 'propertyType' },
  {
    name: 'statuses',
    flatten: true,
    sub_fields: [
      {
        name: 'date',
      },
      {
        name: 'type',
      },
    ],
  },
];
