import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import {
  Auth,
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  authState,
  getAuth,
  signInWithPopup,
} from '@angular/fire/auth';
import { FirebaseService } from '@thesmartflip/data-firebase';
import { NativeLoginService } from './native-login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth: Auth = inject(Auth);;
  authState$ = authState(this.auth);

  get authenticated(): boolean {
    return !!this.auth.currentUser;
  }

  handleSignedInWithDifferentProvider() {
    // Prompt the user.
  }

  // Sign in options
  async login(providerName: string): Promise<User | null> {
    if (Capacitor.isNativePlatform()) {
      //Native login
      let authToken;
      switch (providerName) {
        case 'google':
          authToken = await this.nativeLogin.nativeGoogleLogin();
          break;
        case 'facebook':
          authToken = await this.nativeLogin.nativeFacebookLogin();
          break;
        case 'apple':
          authToken = await this.nativeLogin.nativeAppleLogin();
          break;
      }

      return this.nativeLogin.completeLogin(authToken);
    } else {
      // Web Login
      let provider;
      switch (providerName) {
        case 'google':
          provider = new GoogleAuthProvider();
          break;
        case 'facebook':
          provider = new FacebookAuthProvider();
          break;
        default:
          provider = new OAuthProvider('apple.com');
          break;
      }

      return this.socialSignIn(provider);
    }
  }

  // Shared login for web
  private async socialSignIn(provider: AuthProvider): Promise<User | null> {
    const auth = getAuth();
    const userCredential = await signInWithPopup(auth, provider);

    return userCredential?.user || null;
  }

  signOut(): void {
    this.router.navigate(['/login']);
    this.firebaseService.auth.signOut();
  }

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    public platform: Platform,
    private nativeLogin: NativeLoginService
  ) {}
}
