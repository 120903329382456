import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): Observable<boolean> | boolean {
    return this.auth.authState$.pipe(
      map((auth) => {
        if (auth) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      }),
      take(1)
    );
  }
}
