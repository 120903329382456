import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MapGeocoder } from '@angular/google-maps';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { generalFields } from '@smartflip/data-field-configuration';
import { AddPropertyForm, FieldConfig } from '@smartflip/data-models';
import { UserService, PropertyService, Logging } from '@smartflip/helper-utils';
import { UploadFormComponent } from './../../../../shared/components/property-image/upload-form/upload-form.component';
import { hiddenList } from './add-property-modal.constants';
import {
  createNewFormControl,
  processGoogleAddress,
} from './add-property-modal.helper';

@Component({
  selector: 'app-add-property-modal',
  templateUrl: './add-property-modal.component.html',
  styleUrls: ['./add-property-modal.component.scss'],
})
export class AddPropertyModalComponent implements OnInit {
  public addPropertyForm: FormGroup<AddPropertyForm>;
  public searchForm: UntypedFormGroup;
  public fieldList: FieldConfig[];
  public objectKeys = Object.keys;
  autocomplete: google.maps.places.Autocomplete;

  constructor(
    private dialogRef: MatDialogRef<UploadFormComponent>,
    private fb: UntypedFormBuilder,
    private user: UserService,
    private propertyService: PropertyService,
    private logger: Logging,
    private geocoder: MapGeocoder
  ) {
    this.fieldList = generalFields.filter(
      (field) =>
        !field.name.includes('date') && !hiddenList.includes(field.name)
    );
    const formShape = this.fieldList.reduce(
      (formObj, frmFld) => ({
        ...formObj,
        [frmFld.name]: createNewFormControl(frmFld.name),
      }),
      {}
    );

    this.addPropertyForm = this.fb.group(formShape);
    this.searchForm = this.fb.group({ searchText: new UntypedFormControl('') });
  }

  cancelAddNew() {
    this.dialogRef.close(null);
  }

  onGoogleAddressSelected(addressResult: Record<string, any>) {
    const formValue = processGoogleAddress(addressResult);

    this.addPropertyForm.patchValue(formValue);
  }

  async saveNewProperty() {
    const { address, city, state, zip } = this.addPropertyForm.value;
    const additionalInfo = await this.propertyService.getDataFromVendor({
      address,
      city,
      province: state,
      zip,
    });

    if (!additionalInfo) {
      this.logger.warn(
        `No Data found for: ${address}, ${city}, ${state}, ${zip}`
      );
    }

    if (
      !additionalInfo ||
      !additionalInfo.geoLocation ||
      additionalInfo.geoLocation.length === 0
    ) {
      const latLong = (
        await lastValueFrom(
          this.geocoder.geocode({ address: `${address}, ${city}, ${state}` })
        )
      ).results[0].geometry;
      const latLongString = `${latLong.location
        .lat()
        .toString()} ${latLong.location.lng().toString()}`;

      additionalInfo.geoLocation = `POINT (${latLongString})`;
    }

    const houseDataExtended = {
      address,
      city,
      state,
      zip,
      ...additionalInfo,
    };

    this.dialogRef.close(houseDataExtended);
  }

  async updateWithDefaults() {
    const userDefaults = await this.user.loggedInUser$
      .pipe(take(1))
      .toPromise();
    const { state } = userDefaults.defaults;

    if (state) {
      this.addPropertyForm.patchValue({ state: state }, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.updateWithDefaults().then();
    const center = { lat: 50.064192, lng: -130.605469 };
    // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const input = document.getElementById('ship-address') as HTMLInputElement;
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: false,
    };

    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener(
      'place_changed',
      this.handleSearch.bind(this)
    );
  }

  public handleSearch() {
    try {
      const placeValues = this.autocomplete.getPlace();

      this.onGoogleAddressSelected(placeValues);
    } catch (e) {
      console.log('Search Error', e);
    }
  }
}
