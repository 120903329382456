import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import { FieldConfig, FieldValidator } from '@smartflip/data-models';
import { bindValidations, fieldToFormArray } from '@smartflip/util-form-helper';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  createControl(fields: FieldConfig[]): UntypedFormGroup {
    const group = this.fb.group({});
    let control: UntypedFormControl | UntypedFormArray;

    Object.keys(fields).forEach((field) => {
      const currentField: FieldConfig = fields[field];

      if (currentField.type === 'button') {
        return;
      }
      if (currentField.type === 'formArray') {
        control = fieldToFormArray(currentField, this.fb);
      } else {
        control = this.fb.control(
          {
            value: currentField.value || null,
            disabled: currentField.disabled || false,
          },
          bindValidations(currentField.validations || ([] as FieldValidator[]))
        );
      }
      group.addControl(currentField.name || 'noName', control);
    });

    return group;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }

  constructor(private fb: UntypedFormBuilder) {}
}
