import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyImageComponent } from './components/property-image/property-image.component';
import { UploadFormComponent } from './components/property-image/upload-form/upload-form.component';
import { MaterialModule } from '@smartflip/util-import-helper';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeaturesPhotoCarouselModule } from '@smartflip/carousel';

@NgModule({
  declarations: [
    PropertyImageComponent,
    UploadFormComponent,
    AlertDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FeaturesPhotoCarouselModule,
  ],
  exports: [PropertyImageComponent, UploadFormComponent, AlertDialogComponent],
})
export class SharedModule {}
