<div class="GeneralInformation">
  <mat-card>
    <p>
      <mat-chip-listbox>
        <mat-chip selected color="primary" class="GeneralInformation__Status">{{
          propertyStatus | titlecase
        }}</mat-chip>
        <span
          class="GeneralInformation__StatusDate"
          [innerHTML]="propertyStatusDate"
        ></span>
      </mat-chip-listbox>
    </p>
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12 mb-2">
          <app-property-image
            class="GeneralInformation__Image"
            [imgUrl]="imgUrl"
            [editable]="true"
            [initialSlideIndex]="selectedPhotoIndex"
            imgClass="GeneralInformation__ImageDeep"
            (onThumbnailChange)="updateThumbnailImage($event)"
            [propertyId]="propertyID"
            [propertyPhotos]="images"
          ></app-property-image>
        </div>
        <div
          class="GeneralInformation__ButtonWrapper col-12 col-md-5 flex-wrap flex"
        >
          <button
            *ngIf="loanFeatureEnabled"
            class="GeneralInformation__Button"
            mat-raised-button
            color="primary"
            (click)="viewLoanApplication()"
          >
            Fund this Flip
            <mat-icon>monetization_on</mat-icon>
          </button>
          <button
            *ngIf="propertyStatus !== 'Sold'"
            class="GeneralInformation__Button"
            color="primary"
            mat-raised-button
            (click)="workflowProgress()"
            [disabled]="pullingAPIDataNotAllowed()"
          >
            <span [innerHTML]="workflowButtonText"></span>&nbsp;
            <mat-icon [innerHTML]="'paid-outline'"></mat-icon>
            <span
              *ngIf="pullingAPIDataNotAllowed()"
              class="GeneralInformation__Warning"
              [innerHTML]="'Full address is required to continue.'"
            ></span>
          </button>
          <button
            class="GeneralInformation__Button"
            mat-raised-button
            (click)="refreshGeneralData()"
            [disabled]="pullingAPIDataNotAllowed()"
          >
            <span [innerHTML]="'Retrieve Public Data'"></span>&nbsp;
            <mat-icon>refresh</mat-icon>
            <span
              *ngIf="pullingAPIDataNotAllowed()"
              class="GeneralInformation__Warning"
              >Full address is required to continue.</span
            >
          </button>
          <button
            class="GeneralInformation__Button"
            color="warn"
            mat-raised-button
            (click)="deleteProperty()"
          >
            Delete this Property
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <!-- General Card -->
  <mat-card>
    <header class="GeneralInformation__Header">
      <h4>General Property Info</h4>
    </header>

    <form
      *ngIf="!loading"
      class="GeneralInformation__Form"
      [formGroup]="generalForm"
      (submit)="onSubmit($event)"
    >
      <ng-container
        *ngFor="let field of objectKeys(fieldList); let i = index"
        dynamicField
        [field]="fieldList[field]"
        [group]="generalForm"
      >
        <div *ngIf="i === 5" class="break"></div>
      </ng-container>
    </form>
  </mat-card>

  <!-- Owner Info -->
  <mat-card>
    <header class="GeneralInformation__Header">
      <h4>Owner Information</h4>
    </header>
    <form
      *ngIf="!loading"
      class="GeneralInformation__Form"
      [formGroup]="ownerForm"
    >
      <ng-container
        *ngFor="let field of objectKeys(ownerFieldList); let i = index"
        dynamicField
        [field]="ownerFieldList[field]"
        [group]="ownerForm"
        [className]="i === 2 ? 'flex-30' : ''"
      >
      </ng-container>
    </form>
  </mat-card>

  <mat-card>
    <header class="GeneralInformation__Header">
      <h4>Mortgage Information</h4>
    </header>
    <!-- Mortgage Info -->
    <form
      *ngIf="!loading"
      class="GeneralInformation__Form"
      [formGroup]="mortgageForm"
    >
      <ng-container
        *ngFor="let field of objectKeys(mortgageFieldList); let i = index"
        dynamicField
        [field]="mortgageFieldList[field]"
        [group]="mortgageForm"
        [className]="i === 2 ? 'flex-30' : ''"
      >
      </ng-container>
    </form>
  </mat-card>
</div>
