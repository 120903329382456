<swiper-container
  navigation="true"
  [pagination]="paginationConfiguration"
  [config]="swiperConfig"
  init="false"
  class="PhotoCarousel__Swiper"
  [ngClass]="{ 'PhotoCarousel__Swiper--Fullscreen': fullScreen }"
  #swiper
>
  <swiper-slide *ngFor="let photo of photos; let i = index">
    <img class="PhotoCarousel__Image" [src]="photo.url" alt="{{ photo.alt }}" />
  </swiper-slide>
</swiper-container>
