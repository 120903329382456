import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PlanOption } from '../stripe.constants';

@Component({
  selector: 'stripe-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
})
export class SubscriptionFormComponent {
  @Input()
  paymentForm!: FormGroup;
  @Input() error: string = '';
  @Input() plans: PlanOption[] = [];
  @Input()
  processingPayment: boolean = false;

  @Output() closeForm: EventEmitter<null> = new EventEmitter();
  @Output() payClicked: EventEmitter<MouseEvent> = new EventEmitter();

  close() {
    this.closeForm.emit();
  }

  pay(event: MouseEvent): void {
    this.payClicked.emit(event);
  }
}
