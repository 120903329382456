import { Injectable } from '@angular/core';
import { ComparablesService } from './comparables.service';
import { RoiService } from '@smartflip/ui-utils';
import { BehaviorSubject } from 'rxjs';
import { AddressQueryProps, Comparable } from '@smartflip/data-models';

@Injectable({
  providedIn: 'root',
})
export class PullComparablesService {
  private loadingComps: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loadingComps$ = this.loadingComps.asObservable();

  constructor(
    private comparablesService: ComparablesService,
    private roi: RoiService,
  ) {}

  async pullComps(filterParams?: Record<string, any>): Promise<Comparable[]> {
    this.loadingComps.next(true);
    // TODO: save the geoLocation to use later
    const {
      address,
      city,
      state,
      zip,
      year,
      beds,
      baths,
      geoLocation,
      propertyType,
      dateRange,
    } = this.roi.formGroups.gen.getRawValue() || {};
    let compsParams: AddressQueryProps = {
      address,
      city,
      province: state,
      zip,
      yearBuilt: year,
      beds,
      baths,
      geoLocation,
      propertyType,
      dateRange,
    };

    if (filterParams?.zip) {
      compsParams.zip = filterParams.zip;
      compsParams.geoLocation = null; // force use of the zip code
    }
    if (filterParams) {
      compsParams = {
        ...compsParams,
        ...filterParams,
      };
    }
    // New values must be set by calling component after results are returned
    const compsResults =
      await this.comparablesService.getComparablesForProperty(
        address?.value || null,
        zip?.value || null,
        compsParams,
      );

    this.loadingComps.next(false);

    return compsResults;
  }
}
