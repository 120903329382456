import { FieldConfig } from '@smartflip/data-models';

export const acquisitionFields: FieldConfig[] = [
  {
    type: 'input',
    name: 'appraisalFee',
    label: 'Appraiser',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'base-second-eyes',
    label: 'Second Eyes',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'inspectionFees',
    label: 'Inspections',
    value: '695',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'subtotal-base',
    label: 'Inspections Sub Total',
    value: '695',
    inputType: 'text',
    disabled: true,
    class: 'subtotal',
  },
  {
    type: 'input',
    name: 'closing-costs-hud-fees',
    label: 'Closing Costs(HUD)',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'loan-points-fees',
    label: 'Loan Points',
    inputType: 'text',
    disabled: true,
  },
  {
    type: 'input',
    name: 'franchiseFees',
    label: 'Franchise Fees',
    value: '500',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'atty-fees-buyer',
    label: 'Attorney Fees-Buyer',
    value: '950',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'attorneyFeesSeller',
    label: 'Attorney Fees-Seller',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'atty-fees-lender',
    label: 'Attorney Fees-Lender',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'titlefees',
    label: 'Title Fees',
    value: '1300',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'surveyfees',
    label: 'Survey Fees',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'miscfees',
    label: 'Misc',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'subtotal-fees',
    label: 'Acquisition Sub Total',
    value: '4730',
    inputType: 'text',
    disabled: true,
    class: 'subtotal',
  },
  {
    type: 'input',
    name: 'other-acquisition-costs',
    label: 'Other Acquisition Costs',
    inputType: 'text',
  },
  {
    type: 'input',
    name: 'subtotal-other',
    label: 'Other Sub Total',
    inputType: 'text',
    disabled: true,
    class: 'subtotal',
  },
  // {
  //     type: 'input',
  //     name: 'total-acquisition-costs',
  //     label: 'Total Acquisition Costs',
  //     inputType: 'text'
  // }
];
