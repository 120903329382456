import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Upload } from '@smartflip/data-models';
import { UploadService } from 'apps/app/src/app/shared/services/upload.service';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
})
export class UploadFormComponent implements OnInit {
  imgUrl: string;
  propertyId: string;
  selectedFile: FileList;
  currentUpload: Upload;
  imageForm: UntypedFormGroup;
  tooltipText =
    'System Preferences > Security & Privacy > Privacy Tab > Full Disk Access > Press the lock symbol to unlock [bottom right of window]> Select Chrome and add it. > Now restart Chrome.';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { propertyId: string; imgUrl: string },
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UploadFormComponent>,
    private uploadService: UploadService
  ) {}

  detectFiles(evt: Event) {
    this.selectedFile = (<HTMLInputElement>evt.target).files;
  }

  async startUpload() {
    const file = this.selectedFile.item(0);
    this.currentUpload = new Upload(file);
    this.currentUpload.propertyId = this.propertyId;
    const newImgUrl = await this.uploadService.pushUpload(this.currentUpload);
    this.dialogRef.close(newImgUrl);
  }

  cancelUpload() {
    this.dialogRef.close(null);
  }

  ngOnInit() {
    this.imageForm = this.fb.group({
      imgUrl: this.fb.control(''),
    });
    this.propertyId = this.data.propertyId;
    this.imgUrl = this.data.imgUrl;
  }
}
