<mat-form-field class="margin-top" [formGroup]="group" [ngClass]="field.class">
  <input
    matInput
    [matDatepicker]="picker"
    [formControlName]="field.name"
    [placeholder]="field.label"
    (focus)="scrollIntoView()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint></mat-hint>
  <ng-container
    *ngFor="let validation of field.validations"
    ngProjectAs="mat-error"
  >
    <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{
      validation.message
    }}</mat-error>
  </ng-container>
</mat-form-field>
