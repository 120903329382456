<div class="row">
  <div class="col text-center Login__Form">
    <div class="EmailLoginForm__Wrapper">
      <h6>...or, if you have an email-based account, login below</h6>
      <form [formGroup]="loginForm">
        <ion-item>
          <ion-label for="email">Email:</ion-label>
          <ion-input name="email" formControlName="email"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label for="password">Password:</ion-label>
          <ion-input
            type="password"
            name="password"
            formControlName="password"
          ></ion-input>
        </ion-item>
        <button
          mat-raised-button
          color="primary"
          class="EmailLoginForm__LoginButton m-2"
          (click)="emailLogin()"
          type="button"
        >
          Log in
        </button>
      </form>
    </div>
  </div>
</div>
