<ng-container *ngIf="!!group && !!field">
  <mat-form-field
    [id]="field.name"
    [formGroup]="group"
    [ngClass]="field.class"
    class="Input"
  >
    <mat-label
      class="Label"
      color="primary"
      [innerHTML]="field.label"
    ></mat-label>
    <input
      matInput
      [formControlName]="field.name || 'noName'"
      [placeholder]="field.label || ''"
      [type]="field.inputType || 'text'"
      (focus)="scrollIntoView()"
      [aria-describedby]="field.name || 'no description available'"
    />
    <ng-container
      *ngFor="let validation of field.validations"
      ngProjectAs="mat-error"
    >
      <mat-error
        *ngIf="group.get(field.name || 'noName')?.hasError(validation.name)"
        [innerHTML]="validation.message"
      ></mat-error>
    </ng-container>
  </mat-form-field>
</ng-container>
