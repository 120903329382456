import { Injectable, isDevMode } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  public logEvent(options: { name: string; params: Record<string, unknown> }) {
    if (!isDevMode()) {
      FirebaseAnalytics.logEvent(options);
    }
  }

  public setUserId(params: { userId: string }) {
    if (!isDevMode()) {
      FirebaseAnalytics.setUserId(params);
    }
  }
}
