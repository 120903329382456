export const budgetMetadata = [
  {
    title: 'Acquisition costs',
    name: 'totalAcquisitionCosts',
    abbr: 'acq',
  },
  { title: 'Carrying costs', name: 'totalCarryCosts', abbr: 'cry' },
  { title: 'Repair costs', name: 'totalRepairCosts', abbr: 'rep' },
  { title: 'Selling costs', name: 'totalSellCosts', abbr: 'sel' },
  { title: 'Other costs', name: 'totalOtherCosts', abbr: 'oth' },
];
