import { FirebaseTimeStamp } from '@smartflip/data-models';

export interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GoogleAddressObject {
  route: string;
  street_number: string;
  city: string;
  locality: string; // City
  postal_code: string;
  postal_code_suffix: string;
  administrative_area_level_1: string; // state in US
  administrative_area_level_2: string; // County
  country: string;
  neighborhood: string;
}

export const addressComponentsToObject = (
  arraySrc: GoogleAddressComponent[]
): GoogleAddressObject =>
  arraySrc.reduce(
    (obj, arrayItem) => ({
      ...obj,
      [arrayItem.types[0]]: arrayItem.short_name,
    }),
    {} as GoogleAddressObject
  );

export const convertTimestampToDate = (
  dateValue: Date | FirebaseTimeStamp | undefined
): Date =>
  dateValue && (dateValue as FirebaseTimeStamp).seconds
    ? new Date((dateValue as FirebaseTimeStamp).seconds)
    : (dateValue as Date);
