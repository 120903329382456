<div class="RepairCosts">
  <label class="RepairCosts__Content">Rehab Total: {{ total | currency }}</label>
  <form
    class="dynamic-form"
    [formGroup]="repairForm"
    (submit)="onSubmit($event)"
    class="RepairCosts__Form"
  >
    <!-- <mat-form-field [formGroup]="repairForm"> -->
    <!-- <input matInput [formControlName]="'total'" [placeholder]="'Rehab Total'" disabled> -->
    <button
      mat-fab
      aria-label="Details"
      (click)="showDetails()"
      class="RepairCosts__Content RepairCosts__Button"
    >
      <mat-icon>list</mat-icon>
    </button>
    <!-- </mat-form-field> -->
  </form>
</div>
