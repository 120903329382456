export const propertyTypeList = [
  'Apartment',
  'Commercial',
  'Condo',
  'Duplex',
  'Multi-Family Dwelling',
  'Single Family Dwelling',
  'Townhouse',
];

export const propertyTypeListFull = [
  'Apartment',
  'Commercial',
  'Condo',
  'Multi-Family Dwelling',
  'Single Family Dwelling',
  ' ',
  'Barn',
  'Bed Breakfast',
  'Boat',
  'Boat Slip',
  'Building',
  'Bungalow',
  'Cabin',
  'Camper/RV',
  'Campground',
  'Car Wash',
  'Castle',
  'Cave',
  'Chalet',
  'Co-op',
  'Cottage',
  'Development Site',
  'Dorm',
  'Duplex',
  'Earth House',
  'Entire Floor',
  'Estate',
  'Farm',
  'Flat',
  'Guesthouse',
  'Homestay',
  'Hospitality',
  'Hostal',
  'Hotel',
  'Igloo',
  'Industrial',
  'Inn',
  'Island',
  'Land',
  'Lodge',
  'Loft',
  'Manufactured Home',
  'Marina',
  'Mas',
  'Masía',
  'Mobile Home',
  'Motel',
  'Nature lodge',
  'Other',
  'Parking',
  'Posada',
  'Quadruplex',
  'Religious Facility',
  'Rental Unit',
  'Resort',
  'Retail',
  'Riad',
  'Room',
  'School',
  'Self-Storage',
  'Semi-Detached',
  'Shopping Center',
  'Sports & Entertainment',
  'Studio',
  'Tent',
  'Tipi',
  'Townhouse',
  'Train',
  'Treehouse',
  'Triplex',
  'Unit',
  'Vacation Rental',
  'Villa',
  'Yurt',
];

export const comparablesRangeOptions = [
  { label: '6 months', value: 6 },
  { label: '12 months', value: 12 },
  { label: '24 months', value: 24 },
  { label: '36 months', value: 36 },
];

export const defaultSearchRadius = 2;

export const defaultCompsRange = 6;
