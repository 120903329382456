import { Component, ElementRef } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  field: FieldConfig;
  group: UntypedFormGroup;

  constructor(private el: ElementRef) {}

  scrollIntoView() {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
