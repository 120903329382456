<div class="ComparablesFilterChoices row w-100">
  @if (filterOptions?.length > 0) {
    <div class="p-2 col-12">
      <h3 class="ComparablesFilterChoices__Header mt-0 mb-0">
        Currently Filtering with:
      </h3>
    </div>
  }
  @for (filterItem of filterOptions; track filterItem) {
    <div class="pl-2 pr-2 pt-0 col-12 col-xxl-2 col-lg-3 col-md-4 col-sm-6">
      <label
        class="ComparablesFilterChoices--Bold"
        [innerHTML]="filterItem.label"
      ></label>
      <span>:&nbsp;</span>
      <span class="ComparablesFilterChoices--MarginRight">
        {{ getFilterOptionLabel(filterItem) }}
        @if (filterItem.label === 'Pull Comps from') {
          months
        }
      </span>
    </div>
  }
</div>
