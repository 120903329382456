<span class="Menu">
  <button
    (focus)="noFocus($event)"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Menu button"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      (focus)="noFocus($event)"
      mat-menu-item
      routerLink="properties"
      routerLinkActive="Menu__Link--active"
    >
      <mat-icon>home</mat-icon>
      <span>My Properties</span>
    </button>
    <button
      (focus)="noFocus($event)"
      mat-menu-item
      routerLink="profile"
      routerLinkActive="Menu__Link--active"
    >
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </button>
    <button
      (focus)="noFocus($event)"
      mat-menu-item
      routerLink="support"
      routerLinkActive="Menu__Link--active"
    >
      <mat-icon>help</mat-icon>
      <span>Support</span>
    </button>
    <a
      (focus)="noFocus($event)"
      mat-menu-item
      [href]="privacyUrl"
      target="_blank"
    >
      <mat-icon>shield_person</mat-icon>
      <span>Privacy Policy</span>
    </a>
    <button (focus)="noFocus($event)" mat-menu-item (click)="signOut()">
      <mat-icon>logout</mat-icon>
      <span>Log out</span>
    </button>
    <caption class="Menu__Copyright" mat-menu-item>
      version:
      {{
        version
      }}
    </caption>
    <caption class="Menu__Copyright" mat-menu-item>
      &copy;
      {{
        year
      }}
      Smart Flip, LLC.
    </caption>
  </mat-menu>
</span>
