<mat-card class="HomeListCard" (click)="open(property.id)">
  <app-property-image
    class="HomeListCard__Image"
    [useUpload]="false"
    [imgUrl]="property.imgUrl"
    [disableFullscreen]="true"
  ></app-property-image>

  <span class="HomeListCard__Info"
    >{{ property.gen.address }}<br />
    {{ property.gen.city }}, {{ property.gen.state }}
    {{ property.gen.zip }}
  </span>
  <span class="fill-space"></span>

  <button mat-button mat-button-icon color="primary">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</mat-card>
