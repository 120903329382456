import { Injectable, inject } from '@angular/core';
import { Auth, User, user } from '@angular/fire/auth';
import {
  DocumentData,
  Firestore,
  collection,
  onSnapshot,
  query,
  where,
  CollectionReference,
  DocumentReference,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firestore: Firestore = inject(Firestore);
  public auth: Auth = inject(Auth);
  public user$: Observable<User | null> = user(this.auth);
  propertyCollectionRef: CollectionReference | undefined;
  propCollection: BehaviorSubject<DocumentData[]>; // TODO: set up a property model
  propertyCollectionRefNew: DocumentReference | undefined;

  constructor() {
    this.propCollection = new BehaviorSubject([] as DocumentData[]);
    this.user$.subscribe((u) => {
      if (u) {
        const UID = u.uid;
        const propertyCollection = collection(this.firestore, 'Properties');
        const userQuery = query(propertyCollection, where('user', '==', UID));

        this.propertyCollectionRef = propertyCollection;

        onSnapshot(userQuery, (propertySnapshot: QuerySnapshot) => {
          const tmpList: DocumentData[] = [];

          propertySnapshot.forEach((property) => {
            const docData = property.data();
            tmpList.push({ id: property.id, ...docData });
          });
          this.propCollection.next(tmpList);
        });
      } else {
        this.propertyCollectionRef = undefined;
      }
    });
  }
}
