import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

const handleErrorLevel = () => true;
const handleWarnLevel = (l: Level) => l === Level.warn || l === Level.trace;
const handleTraceLevel = (l: Level) => l === Level.trace;

export enum Level {
  trace = 'trace',
  warn = 'warn',
  error = 'error',
  off = 'off',
};

@Injectable({
  providedIn: 'root',
})
export class Logging {
  env: string;

  private logLevelForInstance: Level = this.environment.logLevel
    ? Level[this.environment.logLevel as keyof typeof Level]
    : Level.warn;

  private envs: {[domain: string]: string} = {
    localhost: 'local',
    thesmartflip: 'prod',
  };

  private getTimestamp(): string {
    return new Date().toISOString();
  }

  private formatMessage(level: Level, message: string): string {
    return `
${Level[level].toUpperCase()} at: ${this.getTimestamp()} in Environment: ${this.env}
${message}

`;
  }

  private getEnv(hostname: string) {
    const baseUrl = hostname.replace(/(app\.)|(\.com)/, '');

    return this.envs[baseUrl] ? this.envs[baseUrl] : 'staging';
  }

  private shouldThisLogHappen(level: Level) {
    if (this.logLevelForInstance === 'off') return false;

    const l = this.logLevelForInstance;

    if (level === Level.error) return handleErrorLevel();
    else if (level === Level.warn) return handleWarnLevel(l);
    else if (level === Level.trace) return handleTraceLevel(l);
    else return true;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject('environment') private environment: any
  ) {
    this.env = this.getEnv(this.document.location.hostname as string);
  }

  trace(message: string) {
    if (this.shouldThisLogHappen(Level.trace)) {
      const formattedLog = this.formatMessage(Level.trace, message);

      console.log(formattedLog);

      return formattedLog;
    }

    return;
  }

  warn(message: string) {
    if (this.shouldThisLogHappen(Level.warn)) {
      const formattedLog = this.formatMessage(Level.warn, message);

      console.warn(formattedLog);

      return formattedLog;
    }

    return;
  }

  error(message: string) {
    if (this.shouldThisLogHappen(Level.error)) {
      const formattedLog = this.formatMessage(Level.error, message);

      console.log(formattedLog);

      return formattedLog;
    }

    return;
  }
}
