import { Validators } from '@angular/forms';
import { FieldValidator } from '@smartflip/data-models';

export const bindValidations = (validations: FieldValidator[]) => {
  if (validations.length > 0) {
    const validList = validations.map((valid) => valid.validator);

    return Validators.compose(validList);
  }

  return null;
};
