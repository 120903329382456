import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AddressQueryProps, Comparable } from '@smartflip/data-models';
import {
  AddressAbbreviationsService,
  PropertyService,
} from '@smartflip/helper-utils';
import { environment } from 'apps/app/src/environments/environment';
import { getAttomComps, getDatafinitiComps } from '@smartflip/datafiniti';

export interface Comps {
  compsUrl: string;
  list: any[];
  error?: string;
}

export const EmptyComps: Comparable[] = [];

@Injectable({
  providedIn: 'root',
})
export class ComparablesService {
  private attomAPIKey = environment.attomApiKey;
  private attomAPIurl = 'https://api.gateway.attomdata.com/property/v2/';
  protected datafinitiAPIKey = environment.datafinitiKey;
  protected datafinitiAPIurl = 'https://api.datafiniti.co/v4/properties/search';
  comps$: BehaviorSubject<Comparable[] | null> = new BehaviorSubject(null);
  error: boolean;
  errorText: string;

  constructor(
    private propertyService: PropertyService,
    private http: HttpClient,
    private addressAbbreviationService: AddressAbbreviationsService
  ) {}

  public setComps(newComps: Comparable[]) {
    this.comps$.next(newComps);
  }

  async getComparablesForProperty(
    address: string,
    zip: string,
    fullSearch: AddressQueryProps
  ): Promise<Comparable[]> {
    const useDatafiniti = true; // TODO: make this dynamic
    let comparablesList;
    let unabbreviatedComparables;

    if (useDatafiniti) {
      const search = {
        ...fullSearch,
        address: this.addressAbbreviationService.abbreviateAddress(
          fullSearch.address
        ),
      };
      comparablesList = await getDatafinitiComps(
        search,
        this.datafinitiAPIKey,
        this.datafinitiAPIurl,
        this.http
      );
    } else {
      comparablesList = await getAttomComps(
        address,
        zip,
        this.attomAPIKey,
        this.attomAPIurl,
        this.http
      );
    }

    if (comparablesList) {
      unabbreviatedComparables = comparablesList.map((comparable) => ({
        ...comparable,
        street: this.addressAbbreviationService.unabbreviateAddress(
          comparable.street
        ),
      }));
      this.propertyService.updateProperty('comps', unabbreviatedComparables);
    }

    return unabbreviatedComparables;
  }

  async init(): Promise<void> {
    // get existing comps stored in Firestore
    const propertyId = this.propertyService.propertyId;
    const { comps } = await this.propertyService.getProperty(propertyId);
    const unabbreviatedComps = comps?.map((comp) => ({
      ...comp,
      street: this.addressAbbreviationService.unabbreviateAddress(comp.street),
    }));
    // Null is used to show the instructions vs "no comps found"
    this.setComps(unabbreviatedComps || null);
  }

  reset() {
    // clear existing comps
    this.setComps(null);
  }
}
