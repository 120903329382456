export const propertyCategories: PropertyCategories = {
  sold: {
    key: 'sold',
    label: 'Sold',
    buttonText: '',
    howToAdd: "Update a property's sold date",
    hint: 'Good Job!',
  },
  active: {
    key: 'active',
    label: 'Active',
    buttonText: 'I Sold This Property',
    howToAdd: "Update a property's purchase date",
    hint: 'Currently owned',
  },
  potential: {
    key: 'potential',
    label: 'potential',
    buttonText: 'I Bought This Property',
    howToAdd: 'Click the "Add Property" button',
    hint: 'Still evaluating',
  },
};

export interface CategoryInfo {
  key: string;
  label: string;
  buttonText: string;
  howToAdd: string; // message when no properties in this category have been found
  hint: string; // short description of category
}

export interface PropertyCategories {
  sold: CategoryInfo;
  active: CategoryInfo;
  potential: CategoryInfo;
}
