import { AddressQueryProps } from '@smartflip/data-models';
import { compPropertyView, mainPropertyView } from './datafiniti.view';
import { formatSystemDate } from '@smartflip/util-formatters';
import { subtractMonthsFromDate } from '@smartflip/utils-data';
import {
  defaultCompsRange,
  defaultSearchRadius,
} from '@smartflip/data-constants';

const createAddressQuery = (args: AddressQueryProps): string => {
  return `address:"${args.address}" AND city:"${args.city}" AND province:${args.province}`;
};

const createCompsQuery = (args: AddressQueryProps): string => {
  const {
    zip,
    propertyType,
    beds,
    baths,
    yearBuilt,
    minYearBuilt,
    maxYearBuilt,
    geoLocation,
    minBaths,
    maxBaths,
    minBeds,
    maxBeds,
    minPrice,
    maxPrice,
    searchRadius,
    dateRange,
  } = args;
  let updatedQuery: string = '';
  const bedRooms = beds || 3;
  const bathRooms = baths || 2;
  const minBedrooms = minBeds || bedRooms - 1;
  const maxBedrooms = maxBeds || minBedrooms + 2;
  const minBathrooms = minBaths || bathRooms - 1;
  const maxBathrooms = maxBaths || minBathrooms + 2;
  const todaysDate = new Date();
  const oldestCompDate = formatSystemDate(
    subtractMonthsFromDate(todaysDate, dateRange || defaultCompsRange),
  );

  updatedQuery += `mostRecentPriceDate:[${oldestCompDate} TO *]`;
  // For beds and baths, don't filter if the property has no data and an explicit filter wasn't set
  if (beds || minBeds || maxBeds) {
    updatedQuery += ` AND numBedroom:>=${minBedrooms} AND numBedroom:<=${maxBedrooms}`;
  }

  if (baths || minBaths || maxBaths) {
    updatedQuery += ` AND numBathroom:>=${minBathrooms} AND numBathroom:<=${maxBathrooms}`;
  }

  if (propertyType) {
    const propertyTypeParameter = Array.isArray(propertyType)
      ? propertyType.reduce((newString, type) => {
          type = type.replace(/-/g, ' '); // API freaks out with hyphens

          if (newString === '') {
            return ` AND (propertyType:${type}`;
          } else {
            return `${newString} OR propertyType:${type}`;
          }
        }, '')
      : ` AND (propertyType:${propertyType.replace(/-/g, ' ')}`;

    updatedQuery += `${propertyTypeParameter})`;
  }

  if (yearBuilt || minYearBuilt || maxYearBuilt) {
    const lowYear = minYearBuilt || Number(yearBuilt) - 50 || 1850;
    const highYear =
      maxYearBuilt || Number(yearBuilt) + 20 || new Date().getFullYear();

    updatedQuery += ` AND (yearBuilt:>=${lowYear} AND yearBuilt:<=${highYear})`;
  }
  if (zip && !geoLocation) {
    updatedQuery += ` AND postalCode:${zip}`;
  }
  if (minPrice) {
    updatedQuery += ` AND transactions.price:>=${minPrice}`;
  }
  if (maxPrice) {
    updatedQuery += ` AND transactions.price:<=${maxPrice}`;
  }
  if (!minPrice && !maxPrice) {
    updatedQuery += ` AND transactions.price:*`; // just make sure there are transactions
  }
  if (geoLocation) {
    const pointParts = geoLocation.replace('(', '').replace(')', '').split(' ');
    const lat = pointParts[1];
    const lon = pointParts[2];

    updatedQuery += ` AND geoLocation:[${lat},${lon},${
      searchRadius || defaultSearchRadius
    },mi]`;
  }

  return updatedQuery;
};

export const createRequestBody = (
  type: 'address' | 'comp',
  args: AddressQueryProps,
) => {
  let query: string;

  if (type === 'address') {
    query = createAddressQuery(args);
  } else {
    query = createCompsQuery(args);
  }

  return {
    query,
    num_records: type === 'address' ? 1 : 10,
    download: false,
    format: 'JSON',
    view: type === 'address' ? mainPropertyView : compPropertyView,
  };
};
