import { Route } from '@angular/router';
import { StripePaymentFormComponent } from './stripe-payment-form/stripe-payment-form.component';

export const featureStripePaymentRoutes: Route[] = [
  {
    path: '**',
    // canActivate? [FeatureGuard],
    pathMatch: 'full',
    component: StripePaymentFormComponent,
  },
];
