import { filterControlMetadata } from '@smartflip/data-field-configuration';
import { dropdownOption } from '@smartflip/data-models';

export const updateFilters = (
  filters: Record<keyof typeof filterControlMetadata, unknown>,
): dropdownOption[] => {
  let returnValue: dropdownOption[] = [];

  if (filters) {
    returnValue = Object.entries(filters).map(
      ([key, filterValue]) =>
        ({
          label: filterControlMetadata[key].label || '',
          value: filterValue || '',
        }) as dropdownOption,
    );
  }

  return returnValue;
};

export const shouldPullCompsFromFilters = (
  filterParams: Record<string, number>,
) => {
  if (!filterParams) return null;

  // set the new query params for comps, filter out nulls
  const usedParams = Object.entries(filterParams).reduce(
    (finalParams, [paramKey, value]) =>
      !!value ? { ...finalParams, [paramKey]: value } : finalParams,
    {},
  );

  return Object.keys(usedParams)?.length > 0 ? usedParams : {};
};

export const buildContent = (streetAddress: string, isHome: boolean) => {
  const content = window.document.createElement('div');
  const pinIcon = isHome ? 'cottage' : 'home_pin';
  const extraClass = isHome ? 'marker-source-property' : '';

  content.innerHTML = `
    <span class="smartflip-marker-icon material-symbols-outlined ${extraClass}">${pinIcon}</span>
    <span class="map-label">${streetAddress}</span>
  `;
  content.className = 'smart-flip-map-marker';

  return content;
};
