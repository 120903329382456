import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyDetailsService {
  private propertyIsSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public propertyIsSet$ = this.propertyIsSet.asObservable();

  public setProperty(newValue: boolean) {
    this.propertyIsSet.next(newValue);
  }
}
