import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { swiperConfig } from '../swiper.config';
import { SwiperPhoto } from '../../../../../data/models/src/photo-carousel.models';

@Component({
  selector: 'app-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss'],
})
export class PhotoCarouselComponent implements AfterViewInit, OnChanges {
  @Input()
  photos: SwiperPhoto[] = [];
  @Input() initialSlideIndex: number = 2;
  @Input() fullScreen: boolean = false;
  @Output() activeSlideIndexChange: EventEmitter<number> = new EventEmitter();
  @ViewChild('swiper')
  swiper!: ElementRef;

  public activeIndex = 0;
  public swiperConfig = swiperConfig;
  public paginationConfiguration = {
    clickable: true,
    dynamicBullets: true,
  };

  private updateActiveIndex(newIndex: unknown) {
    this.activeIndex = newIndex as number;
    this.activeSlideIndexChange.emit(this.activeIndex);
  }

  private activateSwiper() {
    if (this.swiper?.nativeElement) {
      const updatedConfig = {
        ...this.swiperConfig,
        initialSlide: this.initialSlideIndex,
        slidesPerView: 1,
        runCallbacksOnInit: false,
      };

      Object.assign(this.swiper.nativeElement, updatedConfig);

      // setTimeout(() => this.swiper.nativeElement.swiper.slideTo(this.initialSlideIndex, 0), 0);
      this.swiper.nativeElement.initialize();
    }
  }

  ngAfterViewInit(): void {
    this.activateSwiper();
    this.swiper.nativeElement.addEventListener(
      'swiperslidechange',
      (event: CustomEvent<any>) => {
        this.updateActiveIndex(event.detail[0].activeIndex);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activateSwiper();
  }
}
