import {
  Directive,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@smartflip/data-models';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { CheckBoxComponent } from './check-box/check-box.component';

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DatePickerComponent,
  radiobutton: RadioButtonComponent,
  checkbox: CheckBoxComponent,
};

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {
  @Input() field!: FieldConfig;
  @Input() group!: UntypedFormGroup;
  @Input() className: string = '';

  private componentRef: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private renderer2: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.field) {
      return;
    }
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );

    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.className =
      this.className?.length > 0 ? this.className : 'input-class';
    this.renderer2.addClass(
      this.componentRef.location.nativeElement,
      this.className
    );
  }
}
