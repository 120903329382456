<ng-container *ngIf="fieldList && fieldList.length > 0 && durationField">
  <form
    class="dynamic-form"
    [formGroup]="carryForm"
    (submit)="onSubmit($event)"
  >
    <ng-container dynamicField [field]="durationField" [group]="carryForm">
    </ng-container>
    <table>
      <thead>
        <tr>
          <th>Total</th>
          <th>Monthly</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let field of fieldList">
          <tr *ngIf="field.name !== 'duration'">
            <td>
              {{
                (carryForm.getRawValue()[field.name] || 0) *
                  (carryForm.value["duration"] || 1) | currency
              }}&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <ng-container dynamicField [field]="field" [group]="carryForm">
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
</ng-container>
