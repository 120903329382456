import { Component, OnInit, isDevMode } from '@angular/core';
import { connectAuthEmulator, getAuth } from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
} from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { filter, take } from 'rxjs';
import { getPerformance } from '@angular/fire/performance';
import Hotjar from '@hotjar/browser';
import { CustomerService, UserService } from '@smartflip/helper-utils';
import { environment } from '../environments/environment';
import { getApp } from '@angular/fire/app';
import { setStatusBarDefault } from './app.component.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'The Smart Flip';

  constructor(
    private platform: Platform,
    private customerService: CustomerService,
    private userService: UserService // private statusBar: StatusBar // private splashScreen: SplashScreen,
  ) {}

  async detectPlatform() {
    const info = this.platform.is('cordova');

    console.log('Detected Platform is native?', info);
  }

  initializeApp() {
    if (environment['useEmulator']) {
      const auth = getAuth();

      connectAuthEmulator(auth, 'http://127.0.0.1:9099');
      connectFirestoreEmulator(getFirestore(), 'http://127.0.0.1', 8080);
    }

    this.platform.ready().then(() => {
      this.initializeNative();
    });
    this.userService.loggedInUser$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        this.customerService.getCustomerByUserId(user.id);
      });

    if (!isDevMode()) {
      this.initializeProduction();
    }
  }

  initializeProduction() {
    // skip analytics and performance in local development
    const firebaseApp = getApp();

    getPerformance(firebaseApp);
    this.initializeHotJar();
  }
  async initializeNative() {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide().then(() => console.log('Splashscreen hidden'));

      if (this.platform.is('ios')) {
        setStatusBarDefault();
      }
    }
  }

  initializeHotJar() {
    const siteId = 3732250;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }

  ngOnInit() {
    this.detectPlatform();
    this.initializeApp();
  }
}
