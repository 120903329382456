<div class="ImageUpload">
  <h2>Upload New Image</h2>
  <app-property-image
    [imgUrl]="imgUrl"
    [useUpload]="false"
    class="ImageUpload__Image"
  ></app-property-image>

  <form [formGroup]="imageForm">
    <input
      id="imgUrl"
      name="imgUrl"
      [formControlName]="'imgUrl'"
      type="file"
      (change)="detectFiles($event)"
    />
    <button
      mat-button
      #tooltip="matTooltip"
      [matTooltip]="tooltipText"
      matTooltipClass="ImageUpload__Tooltip"
      matTooltipPosition="above"
      matTooltipHideDelay="100000"
      aria-label="Button that displays a tooltip that hides when scrolled out of the container"
      class="ImageUpload__TooltipButton"
    >
      Upload button not working?
    </button>

    <div class="ImageUpload__ButtonWrapper">
      <button
        class="ImageUpload__Button"
        mat-raised-button
        type="button"
        (click)="cancelUpload()"
      >
        Cancel
      </button>
      <button
        class="ImageUpload__Button"
        [ngClass]="{ 'ImageUpload__Button--disabled': !imageForm.dirty }"
        mat-raised-button
        color="primary"
        (click)="startUpload()"
      >
        Upload
      </button>
    </div>
  </form>
</div>
