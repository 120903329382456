import { Injectable } from "@angular/core";
import { addressAbbreviationToWord } from "./address-abbreviation-to-word";
import { addressWordToAbbreviation } from "./address-word-to-abbreviation";

@Injectable({
  providedIn: 'root',
})
export class AddressAbbreviationsService {
  private replaceDotIfAfterAbbreviation(address: string) {
    // Always get the next dot, as previous dots were either removed or replaced with !
    const indexOfDot = address.indexOf('.');
    const beforeDot = address.substring(0, indexOfDot);
    const startOfWordBeforeDot = beforeDot.lastIndexOf(' ') + 1;
    const wordBeforeDot = address.substring(startOfWordBeforeDot, indexOfDot).toLowerCase();
    const afterDot = address.substring(indexOfDot + 1);

    // There should never be a ! in an address, so use it as a placeholder to
    // hide .'s that should remain/are not a . in an abbreviation
    return `${beforeDot}${addressAbbreviationToWord[wordBeforeDot] ? '' : '!'}${afterDot}`;
  }

  private checkForDotAfterAbbreviations(address: string) {
    let copyOfAddress = address;

    while(copyOfAddress.indexOf('.') > -1)
      copyOfAddress = this.replaceDotIfAfterAbbreviation(copyOfAddress);

    return copyOfAddress.replace(/\!/g, '.');
  }

  abbreviateAddress(address: string) {
    const deDotAndLowerCase = (word: string) => word.replace('.', '').toLowerCase();

    const newAddress = address.split(' ')
      .map(component => addressWordToAbbreviation[deDotAndLowerCase(component)]
        ? addressWordToAbbreviation[deDotAndLowerCase(component)].toUpperCase()
        : component
      ).join(' ').trim();

    return this.checkForDotAfterAbbreviations(newAddress);
  }

  unabbreviateAddress(address: string): string {
    return address.split(' ').
      map(component => !!addressAbbreviationToWord[component.toLowerCase()]
        ? addressAbbreviationToWord[component.toLowerCase()]
        : component
      ).join(' ').trim();
  }
}
