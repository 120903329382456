import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import {
  comparablesRangeOptions,
  propertyTypeList,
} from '@smartflip/data-constants';
import { filterControlMetadata } from '@smartflip/data-field-configuration';
import { FieldConfig } from '@smartflip/data-models';
import { defaultMinYearBuilt } from '../comparables.constants';

@Component({
  selector: 'smartflip-comparables-filter',
  templateUrl: './comparables-filter.component.html',
  styleUrls: ['./comparables-filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatSliderModule,
  ],
})
export class ComparablesFilterComponent {
  public filterForm: UntypedFormGroup;
  public formControls: Record<string, UntypedFormControl>[];
  public filterControlData: FieldConfig[];
  public propertyTypes = propertyTypeList;
  public comparablesRangeOptions = comparablesRangeOptions;
  public currentYear = new Date().getFullYear();
  public defaultMinYearBuilt = defaultMinYearBuilt;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ComparablesFilterComponent>,
    private fb: UntypedFormBuilder,
  ) {
    const groupObject = Object.keys(filterControlMetadata).reduce(
      (formGrp, key) => ({ ...formGrp, [key]: [data[key] || ''] }),
      {},
    );
    this.filterForm = this.fb.group(groupObject);
    this.filterControlData = Object.entries(filterControlMetadata).map(
      ([key, value]) => ({ name: key, label: value.label, type: value.type }),
    );
  }

  public cancel() {
    this.dialogRef.close();
  }

  public clearFilter() {
    this.filterForm.reset();
  }

  public saveFilter() {
    this.dialogRef.close(this.filterForm.value);
  }
}
