<form class="dynamic-form" [formGroup]="form" (submit)="handleSubmit($event)">
  <ng-container formArrayName="details">
    <div
      class="row d-flex justify-content-between"
      *ngFor="let ctrl of details.controls; index as i"
    >
      <ng-container [formGroupName]="i">
        <div class="p-2">
          <mat-form-field [id]="'name-' + i" class="demo-full-width">
            <input
              matInput
              formControlName="name"
              placeholder="Name"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="p-2">
          <mat-form-field [id]="'cost-' + i" class="demo-full-width">
            <input
              matInput
              formControlName="cost"
              placeholder="Cost"
              type="number"
            />
          </mat-form-field>
        </div>
        <div class="p-2">
          <button 
            class="RepairCostDetailsRow__Icon--delete"
            mat-button
            mat-mini-fab
            color="warn"
            (click)="removeCost(i)"
            type="button"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</form>
