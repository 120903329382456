export const repairFields = [
  {
    type: 'input',
    label: 'Repair Total',
    inputType: 'text',
    name: 'total',
    value: 20000,
  },
  {
    type: 'input',
    inputType: 'hidden',
    name: 'includeInLoan',
    value: true,
  },
  /*     {
        type: 'input',
        label: 'Other',
        inputType: 'text',
        name: 'other'
    }, */
];
