import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AccountDeletionConfirmationModalComponent } from './account-deletion-confirmation-modal/account-deletion-confirmation-modal.component';
import { MatDialogRef } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [AccountDeletionConfirmationModalComponent],
  exports: [AccountDeletionConfirmationModalComponent],
  providers: [{ provide: MatDialogRef, useValue: {} }],
})
export class FeaturesAccountDeletionConfirmationModalModule {}
