<div class="StripePaymentForm container p-3">
  <h2 class="StripePaymentForm__Title">
    Thank you for choosing The Smart Flip!
  </h2>

  <app-loading
    class="StripePaymentForm__Loader"
    *ngIf="processingPayment$ | async"
  ></app-loading>
  <ng-template #currentSubscription>
    <div class="StripePaymentForm__CurrentSubscription">
      <span [innerHTML]="activePlanDescription"></span>
      <span class="break"></span>
      <button
        mat-button
        mat-raised-button
        (click)="modifyPlan()"
        class="StripePaymentForm__Button"
      >
        Change Plan
      </button>
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="navigateHome()"
        class="StripePaymentForm__Button"
      >
        Home
      </button>
    </div>
  </ng-template>
  <!-- Subscription Form -->
  <ng-container *ngIf="showSubscribe; else currentSubscription">
    <stripe-subscription-form
      [paymentForm]="paymentForm"
      [plans]="plans"
      [error]="error"
      [processingPayment]="!!(processingPayment$ | async)"
      (closeForm)="close()"
      (payClicked)="handlePaymentClicked($event)"
    ></stripe-subscription-form>

    <mat-card class="mt-4 d-flex">
      <div class="container-fluid text-center p-4">
        <h2>Plan Descriptions</h2>
        <hr />
        <div class="row">
          <div class="col-md-2 col-xs-0">&nbsp;</div>
          <div class="col-md-4 col-xs-12 text-center">
            <h3>Pro Tier</h3>
            <div class="center-block Pricing__Price">$49/mo</div>
            <p>
              Everything in the free tier plus manage multiple properties.
              Export your data for use in tax season to share with your
              accountant.
            </p>
          </div>
          <div class="col-md-4 col-xs-12 text-center">
            <h3>Premium Tier</h3>
            <div class="center-block Pricing__Price">$99/mo</div>
            <p>
              Everything in Pro Tier plus advanced analytics and unlimited*
              comparison updates
            </p>
          </div>
          <div class="col-md-2 col-xs-0">&nbsp;</div>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>
