<div class="demo-full-width margin-top" [formGroup]="group">
  <ng-container [ngSwitch]="buttonType">
    <button
      (click)="handleClick($event)"
      *ngSwitchCase="field.img"
      type="submit"
      mat-fab
      color="primary"
    >
      <img [src]="field.img" [alt]="field.label" />
    </button>
    <button
      (click)="handleClick($event)"
      *ngSwitchDefault
      type="submit"
      mat-raised-button
      color="primary"
    >
      {{ field.label }}
    </button>
  </ng-container>
</div>
