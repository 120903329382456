import { Property, SmartFlipUser } from '@smartflip/data-models';

export const setPropertyDefaults = (
  userDefaults: SmartFlipUser,
  defaultProperty: Property
): Property => {
  const {
    loanpoints,
    loanrate,
    appraisalFee,
    state,
    franchiseFees,
    franchiseTransactionFeesPercent,
    commpercent,
    inspectionFees,
    attorneyFeesSeller,
  } = userDefaults.defaults;
  const updatedProperty = structuredClone(defaultProperty);

  updatedProperty.gen.state = state || defaultProperty.gen.state;
  updatedProperty.summary.loanrate =
    loanrate || defaultProperty.summary.loanrate;
  updatedProperty.summary.loanpoints =
    loanpoints || defaultProperty.summary.loanpoints;
  updatedProperty.acq.appraisalFee =
    appraisalFee || defaultProperty.acq.appraisalFee;
  updatedProperty.selling.franchiseTransactionFeesPercent =
    franchiseTransactionFeesPercent ||
    defaultProperty.selling.franchiseTransactionFeesPercent;
  updatedProperty.selling.commpercent =
    commpercent || defaultProperty.selling.commpercent;
  updatedProperty.acq.attorneyFeesSeller =
    attorneyFeesSeller || defaultProperty.acq.attorneyFeesSeller;
  updatedProperty.acq.inspectionFees =
    inspectionFees || defaultProperty.acq.inspectionFees;
  updatedProperty.acq.franchiseFees =
    franchiseFees || defaultProperty.acq.franchiseFees;

  return updatedProperty;
};
