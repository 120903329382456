import { Injectable } from '@angular/core';
import { doc, getFirestore, updateDoc } from '@angular/fire/firestore';
import { PropertyService } from '@smartflip/helper-utils';
import { RoiService, SnackbarService } from '@smartflip/ui-utils';

@Injectable({
  providedIn: 'root',
})
export class GeneralInformationService {
  constructor(
    private propertyService: PropertyService,
    private roiService: RoiService,
    private snackbarService: SnackbarService
  ) {}

  private updatePropertyImages(newImages: string[], propertyID: string) {
    const property = doc(getFirestore(), `Properties/${propertyID}`);

    updateDoc(property, { images: newImages });
  }

  public updateThumbnailImage(newImageUrl: string, propertyID: string) {
    const property = doc(getFirestore(), `Properties/${propertyID}`);

    updateDoc(property, { imgUrl: newImageUrl });
  }

  public async refreshPropertyData(propertyID: string) {
    const { address, city, state, zip } =
      this.roiService.formGroups['gen'].value;
    const extraInfo = await this.propertyService.getDataFromVendor({
      address,
      city,
      province: state,
      zip,
    });
    const hasError = extraInfo === null; // if there's an error in the APIs, this will be null

    if (!hasError) {
      const {
        images,
        lot,
        mortgage,
        owner,
        sqft,
        year,
        beds,
        baths,
        geoLocation,
        propertyType,
      } = extraInfo;

      this.roiService.formGroups['gen'].patchValue({
        lot,
        sqft,
        year,
        beds,
        baths,
        geoLocation,
        propertyType,
      });

      if (images) {
        const saveableImages = images.slice(0, 10);

        this.updateThumbnailImage(images[0], propertyID);
        this.updatePropertyImages(saveableImages, propertyID);
      }

      if (owner) {
        const { first, last, address } = owner;

        this.roiService.formGroups['owner'].patchValue({
          first,
          last,
          address,
        });
      }

      if (mortgage) {
        const { lender, titleCompany, amount } = mortgage;

        this.roiService.formGroups['mortgage'].patchValue({
          lender,
          titleCompany,
          amount,
        });
      }
    } else {
      this.snackbarService.presentToast(
        'middle',
        'Unable to locate data for the address provided.',
        3000,
        'alert'
      );
    }
  }
}
