<div class="AddPropertyModal">
  <h1 class="AddPropertyModal__Title">Search for your property:</h1>
  <form [formGroup]="searchForm">
    <mat-form-field class="AddPropertyModal__SearchField">
      <mat-label>Search Here</mat-label>
      <input
        matInput
        id="ship-address"
        name="ship-address"
        required
        autocomplete="off"
        formControlName="searchText"
        tabindex="0"
    /></mat-form-field>
  </form>

  <form class="AddPropertyModal__Form" [formGroup]="addPropertyForm">
    <ng-container
      *ngFor="let field of objectKeys(fieldList); let i = index"
      dynamicField
      [field]="fieldList[field]"
      [group]="addPropertyForm"
    >
      <div *ngIf="i !== 3" class="break"></div>
    </ng-container>
  </form>

  <div class="AddPropertyModal__Footer">
    <button
      class="AddPropertyModal__Button"
      mat-raised-button
      color="secondary"
      (click)="cancelAddNew()"
    >
      Cancel
    </button>
    <button
      class="AddPropertyModal__Button"
      mat-raised-button
      color="primary"
      (click)="saveNewProperty()"
      [ngClass]="{
        'AddPropertyModal__Button--disabled': addPropertyForm.invalid
      }"
      [innerHTML]="'Continue'"
    ></button>
  </div>
</div>
