export const otherFields = [
  {
    type: 'input',
    label: 'Other 1',
    inputType: 'text',
    name: 'other1',
    value: 0,
  },
  {
    type: 'input',
    label: 'Other 2',
    inputType: 'text',
    name: 'other2',
    value: 0,
  },
];
