import { Component, Input } from '@angular/core';
import { dropdownOption } from '@smartflip/data-models';

@Component({
  selector: 'smartflip-filter-choices',
  templateUrl: './filter-choices.component.html',
  styleUrl: './filter-choices.component.scss',
})
export class FilterChoicesComponent {
  @Input() filterOptions: dropdownOption[] = [];

  public getFilterOptionLabel(option: dropdownOption): string {
    return option.label === 'Property Type'
      ? (option.value as unknown as string[]).join(', ')
      : option.value;
  }
}
