import { FieldConfig } from '@smartflip/data-models';

// the types aren't used here
// TODO: update the code to just use the dynamic field instead of the custom view
export const filterControlMetadata: Record<string, FieldConfig> = {
  minPrice: { type: 'input', label: 'Min Price' },
  maxPrice: { type: 'input', label: 'Max Price' },
  minBeds: { type: 'input', label: 'Min Beds' },
  maxBeds: { type: 'input', label: 'Max Beds' },
  minBaths: { type: 'input', label: 'Min Baths' },
  maxBaths: { type: 'input', label: 'Max Baths' },
  zip: { type: 'input', label: 'Zip Code' },
  searchRadius: { type: 'input', label: 'Search Radius' },
  propertyType: { type: 'input', label: 'Property Type' },
  minYearBuilt: { type: 'input', label: 'Built After' },
  maxYearBuilt: { type: 'input', label: 'Built Before' },
  dateRange: { type: 'radio', label: 'Pull Comps from' },
};

export const summaryFields: FieldConfig[] = [
  {
    type: 'input',
    label: 'Purchase price',
    inputType: 'text',
    name: 'price',
    value: 100000,
  },
  {
    type: 'input',
    label: 'Annual property tax',
    inputType: 'text',
    name: 'tax',
    value: 3000,
  },
  {
    type: 'input',
    label: 'Sell price',
    inputType: 'text',
    name: 'sell',
  },
  {
    type: 'input',
    label: 'Interest Rate Assumption',
    inputType: 'text',
    name: 'loanrate',
    value: 12,
  },
  {
    type: 'input',
    label: 'Loan Points',
    inputType: 'text',
    name: 'loanpoints',
    value: 0.03,
    disabled: false,
  },
  {
    type: 'input',
    label: 'Loan to Value',
    inputType: 'text',
    name: 'loantovalue',
    class: 'locked',
    disabled: true,
  },
  {
    type: 'input',
    label: 'Loan amount',
    inputType: 'text',
    name: 'loan',
    class: 'locked',
    disabled: true,
  },
];
