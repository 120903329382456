export const PRODUCTS = {
  pro: {
    id: 'prod_OzNsCmvqMWoLyX',
    label: 'Pro Plan',
  },
  premium: {
    id: 'prod_OyJuHFZZWa5yrx',
    label: 'Premium Plan',
  },
  enterprise: {
    id: 'prod_OzNfMAoyCeLtSH',
    label: 'Enterprise Plan',
  },
};