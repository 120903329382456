import { formatUSCurrency } from '@smartflip/util-formatters';
import { Chart } from 'chart.js';

// TODO: import theme vars instead of hard coding
export const DEFAULT_CHART_BACKGROUNDS = [
  'rgba(0, 121, 107, 0.75)',
  'rgba(2, 136, 209, 0.75)',
  'rgba(211, 47, 47, 0.75)',
];

export const DEFAULT_CHART_BORDERS = [
  'rgba(0, 121, 107, 0.9)',
  'rgba(2, 136, 209, 0.9)',
  'rgba(211, 47, 47, 0.9)',
];

export const DEFAULT_CHART_LABELS = ['Green', 'Blue', 'Red'];

export const DEFAULT_GLOBAL_OPTIONS = {};

export const DEFAULT_CHART_PIE_OPTIONS = {
  showTooltips: true,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: 'rgba(0,0,0,0)',
    },
    legend: {
      display: true,
      align: 'center',
      position: 'top',
      labels: {
        generateLabels: (chart: Chart) => {
          const datasets = chart.data.datasets || [];
          const firstDataset = datasets[0];
          const newLabels = firstDataset.data.map((data, i) => ({
            text: `${chart?.data?.labels?.[i]} - ${formatUSCurrency(
              data as number
            )}`,
            /* @ts-ignore-next-line */
            fillStyle: firstDataset?.backgroundColor[i],
          }));

          return newLabels;
        },
      },
    },
    tooltip: {
      callback: {
        label: (tooltipItem: any, data: any): string => {
          const indx = tooltipItem.index;
          const label = data.labels[indx] || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[indx];

          value = typeof value === 'string' ? parseFloat(value) : value;

          return `${label}: $${value
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
    },
  },
};

export const DEFAULT_CHART_BAR_OPTIONS = {
  data: { labels: ['profit', 'expenses'] },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      formatter: (value: number) => formatUSCurrency(Number(value)),
    },
  },
  tooltip: {
    callback: {
      label: (label: any): string =>
        `$${label.formattedValue
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    },
  },
  scales: {
    y: {
      beginAtZero: false,
    },
  },
};
