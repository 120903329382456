export const sellingFields = [
  {
    label: 'Adv / Marketing',
    name: 'marketing',
    type: 'input',
    inputType: 'text',
  },
  {
    label: 'Staging',
    name: 'staging',
    type: 'input',
    inputType: 'text',
  },
  {
    label: 'Realtor Commission %',
    name: 'commpercent',
    type: 'input',
    inputType: 'text',
    value: 4.5,
  },
  {
    label: 'Realtor Commission',
    name: 'comm',
    type: 'input',
    inputType: 'text',
    disabled: true,
  },
  {
    label: 'Closing Costs from HUD',
    name: 'closing',
    type: 'input',
    inputType: 'text',
  },
  {
    label: 'Inspection Repair Issues',
    name: 'repair',
    type: 'input',
    inputType: 'text',
  },
  {
    label: 'Franchise Transaction Fees %',
    name: 'franchiseTransactionFeesPercent',
    type: 'input',
    inputType: 'text',
    value: 3,
  },
  {
    label: 'Franchise Transaction Fees',
    name: 'trans-fees',
    type: 'input',
    inputType: 'text',
    disabled: true,
  },
  {
    label: 'Franchise Assoc Royalty Fee %',
    name: 'royalty-fees-percent',
    type: 'input',
    inputType: 'text',
    value: 2,
  },
  {
    label: 'Franchise Assoc Royalty Fee',
    name: 'royalty-fees',
    type: 'input',
    inputType: 'text',
    disabled: true,
  },
];
