import { AttomProperty, AttomResponseType } from '@smartflip/data-attom';
import { Comparable } from '@smartflip/data-models';

export const convertResponseToComp = (
  propertyResponse: AttomProperty
): Comparable => {
  const acre = 43560; // for converting an acre to square feet
  const {
    '@DistanceFromSubjectPropertyMilesCount': distanceFromSource,
    '@_StreetAddress': street,
    '@_City': city,
    '@_State': state,
    '@_PostalCode': zipcode,
  } = propertyResponse.COMPARABLE_PROPERTY_ext;
  const {
    '@TotalBathroomCount': baths,
    '@TotalBedroomCount': beds,
    '@GrossLivingAreaSquareFeetCount': finishedSqFt,
    STRUCTURE_ANALYSIS: { '@PropertyStructureBuiltYear': yearBuilt },
  } = propertyResponse.COMPARABLE_PROPERTY_ext.STRUCTURE;
  const { '@TransferDate_ext': lastSoldDate, '@PropertySalesAmount': price } =
    propertyResponse.COMPARABLE_PROPERTY_ext.SALES_HISTORY;
  const { '@LotSquareFeetCount': lotSizeInSqFt } =
    propertyResponse.COMPARABLE_PROPERTY_ext.SITE;
  const lotSize =
    Number(lotSizeInSqFt) < acre / 4
      ? Number(lotSizeInSqFt).toFixed(2) + ' sqft'
      : (Number(lotSizeInSqFt) / acre).toFixed(2) + ' acres';

  return {
    street,
    city,
    state,
    zipcode,
    lastSoldDate,
    lastSoldPrice: Number(price),
    lotSize,
    bathrooms: Number(baths),
    bedrooms: Number(beds),
    finishedSqFt,
    yearBuilt,
    distanceFromSource: Number(Number(distanceFromSource).toFixed(2)),
  };
};

export const getCompsFromResponse = (
  apiData: AttomResponseType
): Comparable[] =>
  apiData.RESPONSE_GROUP.RESPONSE.RESPONSE_DATA.PROPERTY_INFORMATION_RESPONSE_ext.SUBJECT_PROPERTY_ext.PROPERTY.filter(
    (property) => !!property.COMPARABLE_PROPERTY_ext
  ).map(convertResponseToComp);

export const getPropteryFromReponse = (
  apiData: AttomResponseType
): Comparable[] =>
  apiData.RESPONSE_GROUP.RESPONSE.RESPONSE_DATA.PROPERTY_INFORMATION_RESPONSE_ext.SUBJECT_PROPERTY_ext.PROPERTY.filter(
    (property) => !!property.COMPARABLE_PROPERTY_ext
  ).map(convertResponseToComp);
