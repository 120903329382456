import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CategorizedProperties, Property } from '@smartflip/data-models';
import { AuthService } from '@smartflip/authentication';
import {
  CustomerService,
  PropertyService,
  createPropertyList,
} from '@smartflip/helper-utils';
import { propertyCategories } from '../../shared/constants';
import { AddPropertyModalComponent } from '../property-details/components/add-property-modal/add-property-modal.component';
import { subscriptionWarningText } from './home-list.constants';
import { hasNoProperties } from './home-list.helper';
import { LoadingService } from '@smartflip/ui-utils';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss'],
})
export class HomeListComponent implements OnInit {
  properties$: Observable<CategorizedProperties>;
  loading = true;
  // Start assuming free plan
  // Values adjusted when plan is found
  showAll = false;
  showBanner = true;
  disableAddNewButton = true;
  cats = propertyCategories;
  public subscriptionWarningText = subscriptionWarningText;

  constructor(
    private propService: PropertyService,
    private router: Router,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private loadingService: LoadingService
  ) {}

  public hideBanner() {
    this.showBanner = false;
  }

  public noProperties(propertyList: Property[]): boolean {
    return propertyList.length === 0;
  }

  public newProperty() {
    this.dialog
      .open(AddPropertyModalComponent)
      .afterClosed()
      .subscribe((newPropertyData) => {
        if (newPropertyData) {
          this.addNewProperty(newPropertyData);
        }
      });
  }

  public exportData() {
    this.router.navigate(['/home/export']);
  }

  private async addNewProperty(newPropertyData) {
    const loader = await this.loadingService.showLoading();

    this.propService
      .addProperty(newPropertyData)
      .then((newPropertyId: string) => {
        this.propService.updateProperty('gen', newPropertyData, newPropertyId);
        this.router.navigate(['/home', newPropertyId]);
      })
      .finally(() => loader.dismiss());
  }

  private limitViewByPlan() {
    combineLatest([
      this.customerService.hasActiveSubscription$,
      this.propService.$properties,
    ])
      .pipe(delay(600))
      .subscribe(([hasActiveSubscription, properties]) => {
        const updatedProperties = createPropertyList(
          properties as Property[],
          hasActiveSubscription
        );
        this.showAll = hasActiveSubscription;
        this.showBanner = !hasActiveSubscription;
        this.disableAddNewButton =
          !hasActiveSubscription && !hasNoProperties(updatedProperties);

        this.properties$ = of(updatedProperties);
        this.loading = false;
      });
  }

  ngOnInit() {
    this.limitViewByPlan();
  }
}
