import { Component, OnInit } from '@angular/core';
import { AuthService } from '@smartflip/authentication';
import { PRIVACY_POLICY_URL } from '@smartflip/data-constants';

const appVersion = require('./../../../../../package.json').version;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public version: string;
  public year: number = new Date().getFullYear();
  public privacyUrl = PRIVACY_POLICY_URL;

  constructor(
    private auth: AuthService /*  private appVersion: AppVersion */
  ) {}

  noFocus(evt: Event) {
    (evt.target as HTMLElement).blur();
  }

  signOut() {
    this.auth.signOut();
  }

  ngOnInit() {
    this.version = appVersion;
  }
}
