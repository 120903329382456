import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  isIos: boolean = true;
  isPortrait: boolean = true;

  constructor(private platform: Platform) {
    this.isIos = this.platform.is('ios');
    this.platform.ready().then(() => {
      ScreenOrientation.addListener('screenOrientationChange', () => {
        ScreenOrientation.orientation().then((orientation) => {
          this.isPortrait =
            orientation.type === 'portrait-primary' ||
            orientation.type === 'portrait-secondary';
        });
      });
    });
  }
}
