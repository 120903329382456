import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { SelectComponent } from './select/select.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ButtonComponent } from './button/button.component';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@smartflip/util-import-helper';
import { IonicModule } from '@ionic/angular';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';

@NgModule({
  declarations: [
    AutoCompleteComponent,
    SelectComponent,
    DatePickerComponent,
    ButtonComponent,
    InputComponent,
    DynamicFieldDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IonicModule,
  ],
  exports: [
    AutoCompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    DatePickerComponent,
    ButtonComponent,
    InputComponent,
    DynamicFieldDirective,
  ],
})
export class FormFieldsModule {}
