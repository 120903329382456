<div class="PropertyImage">
  <div class="PropertyImage__Wrapper">
    <app-photo-carousel
      class="PropertyImage__Carousel"
      [initialSlideIndex]="initialSlideIndex"
      [photos]="propertyPhotos"
    ></app-photo-carousel>
    <!-- <div
      class="PropertyImage__Image"
      [ngClass]="imgClass"
      [ngStyle]="{ 'background-image': 'url(' + imgUrl + ')' }"
    ></div> -->
  </div>
  <!-- Zoom button -->
  <button
    *ngIf="!disableFullscreen"
    class="PropertyImage__Button"
    mat-raised-button
    color="primary"
    (click)="fullScreen()"
    [disabled]="usingDefaultImage()"
  >
    Fullscreen
  </button>

  <!-- Upload button -->
  <ng-container *ngIf="useUpload">
    <button
      *ngIf="editable; else warning"
      class="PropertyImage__Button"
      mat-raised-button
      color="primary"
      (click)="uploadImage()"
    >
      Upload New Photo
    </button>
    <ng-template #warning>
      <p class="PropertyImage__UploadWarning">
        Please log into
        <a href="https://app.thesmartflip.com" target="_blank"
          >https://app.thesmartflip.com</a
        >
        to upload a new photo
      </p>
    </ng-template>
  </ng-container>
</div>
