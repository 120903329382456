import {
  AddressQueryProps,
  Comparable,
  DatafinitiProperty,
  Transaction,
} from '@smartflip/data-models';
import { SQUARE_FEET_PER_ACRE } from '@smartflip/data-constants';
import {
  convertPointStringToLatLong,
  getLastStatus,
  haversineDistance,
} from '@smartflip/utils-data';
import { getLastSoldTransaction } from '../datafiniti.helper';

const convertDatafinitiPropertyToComp = (
  comparableResponse: DatafinitiProperty,
  sourceGeo: string = ''
): Comparable => {
  const {
    address: street,
    city = '',
    province: state = '',
    postalCode: zipcode = '',
    numBedroom: beds,
    numBathroom: baths,
    geoLocation = '',
    // mostRecentPriceAmount: price,
    // mostRecentPriceDate: lastSoldDate = '',
    floorSizeValue = 0,
    floorSizeUnit,
    lotSizeValue = 0,
    lotSizeUnit,
    propertyType,
    transactions,
    yearBuilt,
    statuses,
  } = comparableResponse;
  const finishedSqFtNum = floorSizeUnit?.includes('ft')
    ? floorSizeValue
    : Math.round(floorSizeValue * SQUARE_FEET_PER_ACRE);
  const finishedSqFt = finishedSqFtNum.toString();
  const lotSqFtNum = lotSizeUnit?.includes('ft')
    ? lotSizeValue
    : Math.round(lotSizeValue * SQUARE_FEET_PER_ACRE);
  const lotSize = lotSqFtNum.toString();
  const distanceFromSource = geoLocation
    ? haversineDistance(
        convertPointStringToLatLong(geoLocation),
        convertPointStringToLatLong(sourceGeo)
      ).toFixed(1)
    : '';
  const lastSoldArray = getLastSoldTransaction(transactions as Transaction[]);
  // lastSoldArray is sorted so we can grab the first one
  const lastSold =
    lastSoldArray?.length > 0
      ? lastSoldArray[0]
      : { price: null, saleDate: '' };
  const { price, saleDate: lastSoldDate = '' } = lastSold;
  const salesStatus = getLastStatus(statuses);

  return {
    street,
    city,
    state,
    zipcode,
    lastSoldDate,
    lastSoldPrice: Number(price),
    lotSize,
    bathrooms: Number(baths),
    bedrooms: Number(beds),
    geoLocation,
    finishedSqFt,
    yearBuilt: yearBuilt?.toString() || '',
    propertyType,
    salesStatus,
    distanceFromSource: Number(Number(distanceFromSource).toFixed(2)),
  };
};

export const getCompsFromDatafinitiResponse = (
  comparableResponses: {
    records: DatafinitiProperty[];
  },
  sourceData: AddressQueryProps
) =>
  comparableResponses.records.map((propertyRecord) => {
    return convertDatafinitiPropertyToComp(
      propertyRecord,
      sourceData.geoLocation
    );
  });
