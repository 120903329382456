<div class="PropertyWorkflowModal">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 [innerHTML]="modalTitle"></h1>
      </div>
    </div>
    <!-- Form Row -->
    <div class="row">
      <div class="col">
        <form [formGroup]="updateForm">
          <ng-container
            *ngFor="let field of objectKeys(fieldList)"
            dynamicField
            [field]="fieldList[field]"
            [group]="updateForm"
          >
          </ng-container>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="PropertyWorkflowModal__ActionRow col">
        <hr class="PropertyWorkflowModal__Divider" />
        <button
          class="PropertyWorkflowModal__Button"
          mat-raised-button
          (click)="cancel()"
          [innerHTML]="'Cancel'"
        ></button>
        <button
          class="PropertyWorkflowModal__Button"
          mat-raised-button
          color="primary"
          (click)="save()"
          [innerHTML]="'Proceed'"
        ></button>
      </div>
    </div>
  </div>
</div>
