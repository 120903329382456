import { Component } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  field: FieldConfig;
  group: UntypedFormGroup;

  public getFieldOptions(field: FieldConfig) {
    return field.options.map((option) => option as string);
  }
}
