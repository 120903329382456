import { FormControl, UntypedFormGroup } from '@angular/forms';

export class FormGroupObject {
  acq!: UntypedFormGroup;
  carry!: UntypedFormGroup;
  repair!: UntypedFormGroup;
  repairdetail!: UntypedFormGroup;
  selling!: UntypedFormGroup;
  other!: UntypedFormGroup;
  summary!: UntypedFormGroup;
  gen!: UntypedFormGroup;
  owner!: UntypedFormGroup;
  mortgage!: UntypedFormGroup;
  loan!: UntypedFormGroup;
  comps!: UntypedFormGroup;
  compsFilters!: UntypedFormGroup;

  constructor() {}
}

export type FormGroupFields = keyof FormGroupObject;

export interface AddPropertyForm {
  name: FormControl<string>;
  address: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zip: FormControl<string>;
}

export interface dropdownOption {
  label: string;
  value: string;
}
