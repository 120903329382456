<mat-accordion class="BudgetPanel" color="primary">
  <mat-expansion-panel
    *ngFor="let subBudget of budgets"
    [ngSwitch]="subBudget.abbr"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ subBudget.title }}
        {{ roi.calculationValues[subBudget.name] | currency }}
        <span class="BudgetPanel__RightSide" *ngIf="subBudget.abbr === 'rep'">
          <mat-checkbox
            (change)="toggleRepair()"
            [checked]="includeRepairInLoan"
          ></mat-checkbox>
          <label>&nbsp;&nbsp;Include in Loan</label>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-acquisition-costs *ngSwitchCase="'acq'"></app-acquisition-costs>
    <app-carrying-costs *ngSwitchCase="'cry'"></app-carrying-costs>
    <app-repair-costs *ngSwitchCase="'rep'"></app-repair-costs>
    <app-selling-costs *ngSwitchCase="'sel'"></app-selling-costs>
    <app-other-costs *ngSwitchCase="'oth'"></app-other-costs>
  </mat-expansion-panel>
</mat-accordion>
