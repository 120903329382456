import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'smartflip-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.scss'],
})
export class LoginEmailFormComponent {
  @Input() loginForm: UntypedFormGroup;
  @Output() loginButtonClicked: EventEmitter<null> = new EventEmitter();

  emailLogin() {
    this.loginButtonClicked.emit();
  }
}
