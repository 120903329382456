<div class="PhotoCarouselModal">
  <button
    class="PhotoCarouselModal__CloseButton"
    (click)="closeModal()"
  ></button>
  <!-- Carousel -->
  <app-photo-carousel
    [photos]="photos"
    (activeSlideIndexChange)="updateActiveIndex($event)"
    [initialSlideIndex]="2"
    [fullScreen]="true"
  ></app-photo-carousel>
  <!-- Bottom Row -->
  <!-- <div class="PhotoCarouselModal__ButtonRow">
    <button
      mat-raised-button
      class="PhotoCarouselModal__SelectImage"
      color="primary"
      (click)="selectForThumbnail()"
    >
      Set as Thumbnail
    </button>
  </div> -->
</div>
