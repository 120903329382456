import { Comparable, DatafinitiStatus } from '@smartflip/data-models';

export const compare = (
  a: number | string,
  b: number | string,
  isAsc: boolean
) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

export const generateEstimatedARV = (selectedComps: Comparable[]): number =>
  selectedComps.reduce(
    (ARV, comp, _, { length }) => ARV + comp.lastSoldPrice / length,
    0
  );

export const getLastStatus = (
  statusesList: DatafinitiStatus[] = []
): string => {
  const mostRecentStatus = statusesList.reduce(
    (lastStatus, currentStatus) => {
      const lastDate = lastStatus.date || '';
      let mostRecent: DatafinitiStatus;

      if (currentStatus.date) {
        mostRecent = currentStatus.date > lastDate ? currentStatus : lastStatus;
      } else {
        const mostRecentDate: string =
          currentStatus?.dateSeen?.sort().pop() || '';

        mostRecent =
          mostRecentDate > lastDate
            ? { ...currentStatus, date: mostRecentDate }
            : lastStatus;
      }

      return mostRecent;
    },
    { date: '' } as DatafinitiStatus
  );

  return mostRecentStatus?.type || '';
};
