<div class="demo-full-width margin-top" [formGroup]="group">
  <mat-label
    class="radio-label-padding"
    [innerHTML]="field.label + ':'"
  ></mat-label>
  <mat-radio-group [formControlName]="field.name">
    <mat-radio-button
      *ngFor="let item of getFieldOptions(field)"
      [value]="item"
      [innerHTML]="item"
    >
    </mat-radio-button>
  </mat-radio-group>
</div>
