import {
  Chart,
  BarController,
  PieController,
  ArcElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const registerChartPlugins = () => {
  Chart.register(
    BarController,
    PieController,
    ArcElement,
    BarElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip,
    ChartDataLabels
  );
};
