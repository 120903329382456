import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { statusIconMap } from '../comparables.constants';

@Component({
  selector: 'smartflip-status-legend',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './statusLegend.component.html',
  styleUrl: './statusLegend.component.scss',
})
export class StatusLegendComponent {
  public showPopup = false;
  public statuses = Object.entries(statusIconMap).map(([key, value]) => ({
    key,
    value,
  }));
}
