<div class="Comparables">
  @if (propertyIsSet) {
    <div class="Comparables__MapToggleRow container-fluid d-flex">
      <div
        class="p-2 col-6 d-flex justify-content-start align-items-center"
        *ngIf="showMapToggle"
      >
        <mat-checkbox
          (change)="toggleMap()"
          [checked]="showMap"
          aria-label="Show Map Checkbox"
        ></mat-checkbox>
        <label class="Comparables__MapToggleLabel">&nbsp;&nbsp;Show Map</label>
      </div>

      <div
        class="p-2 col-6 d-flex justify-content-end align-items-center"
        [ngClass]="isNative ? 'col-12' : 'col-6'"
      >
        <button
          mat-button
          aria-label="Filter button"
          (click)="showFilter()"
          color="primary"
          class="Comparables__Button"
        >
          <span
            class="Comparables__FilterLabel"
            [innerHTML]="'Filter Results'"
          ></span>
          <mat-icon>tune</mat-icon>
        </button>
      </div>
    </div>

    <section
      class="Comparables__MapAndTableContainer"
      [ngClass]="{ 'Comparables__MapAndTableContainer--NoMap': !showMap }"
    >
      <div class="Comparables__ListSection">
        <p class="warning" *ngIf="error">{{ errorText }}</p>
        <mat-card *ngIf="!error">
          <smartflip-comparables-filter-actions
            [pullCompsButtonText]="getCompsButtonText"
            [selectedComps]="selectedComps"
            [arvButtonText]="calculateARVButtonText"
            [filterOptions]="currentFilters"
            (pullCompsClicked)="pullComps($event)"
          ></smartflip-comparables-filter-actions>

          <ng-container *ngIf="loadingComps$ | async; else loaded">
            <div class="Comparables__SpinnerWrapper">
              <mat-spinner class="Comparables__Spinner"></mat-spinner>
            </div>
          </ng-container>

          <ng-template #loaded>
            <ng-container *ngIf="compsList$ | async; let compsList">
              <ng-container *ngIf="compsList.length > 0; else searchError">
                <div class="container-fluid Comparables__List">
                  <app-comparables-table
                    class="Comparables__CompsTable"
                    [compsSource]="compsList"
                    [highlightedAddress]="highlightedAddress$ | async"
                    (selectionChange)="updateCompsSelected($event)"
                    (hoverChange)="onHoverChange($event)"
                  ></app-comparables-table>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="compsList$ | async; else notSearched"
            ></ng-container>
          </ng-template>
        </mat-card>
      </div>
      @if (showMap) {
        <div class="Comparables__MapSection">
          @if (this.comparablesMarkers) {
            <lib-interactive-map
              [lat]="location[0]"
              [long]="location[1]"
              [locations]="comparablesMarkers"
              [startingLocationLabel]="currentPropertyLabel"
              (markerSelected)="highlightPropertyInList($event)"
            ></lib-interactive-map>
          }
        </div>
      }
    </section>
  } @else {
    <p class="warning" [innerHTML]="enterAddressWarning"></p>
  }

  <!-- NO DATA -->
  <ng-template #searchError>
    <p class="warning" [innerHTML]="comparablesWarningMessage"></p>
  </ng-template>
  <ng-template #notSearched>
    <div class="container-fluid">
      <p [innerHTML]="comparablesInstructions"></p>
    </div>
  </ng-template>
</div>
