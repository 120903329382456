import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddressQueryProps,
  DatafinitiProperty,
  Transaction,
} from '@smartflip/data-models';
import { DatafinitiExtraInfo } from './datafiniti.models';
import {
  convertPropertyToAddedInfo,
  getLastSoldTransaction,
} from './datafiniti.helper';
import { BehaviorSubject, catchError, lastValueFrom, of } from 'rxjs';
import { createRequestBody } from './datafiniti.query';

@Injectable({
  providedIn: 'root',
})
export class DatafinitiService {
  private datafinitiUrl: string =
    'https://api.datafiniti.co/v4/properties/search';
  private datafinitiKey: string = this.environment.datafinitiKey;
  public datafinitiProperty: BehaviorSubject<DatafinitiProperty> =
    new BehaviorSubject(null as unknown as DatafinitiProperty);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: any
  ) {}

  async getPropertyByAddress(
    fullAddress: AddressQueryProps
  ): Promise<DatafinitiExtraInfo | null> {
    const propertyURL = this.datafinitiUrl;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.datafinitiKey}`,
      'Content-Type': 'application/json',
    });
    const { address, city, province, zip } = fullAddress;
    const json = createRequestBody('address', {
      address,
      city,
      province,
      zip,
    });
    // const propertyDetail: DatafinitiProperty = mockProperties[1];
    const propertyDetail = await lastValueFrom(
      this.http
        .post<{ records: DatafinitiProperty[] }>(encodeURI(propertyURL), json, {
          headers,
        })
        .pipe(
          catchError((e) => {
            console.log(e);
            return of({ records: [] });
          })
        )
    );
    let addedInfo: DatafinitiExtraInfo | null = null;

    if (propertyDetail.records?.length > 0) {
      const sourceProperty = propertyDetail.records[0];

      addedInfo = convertPropertyToAddedInfo(sourceProperty);
      this.datafinitiProperty.next(sourceProperty);
    }

    return addedInfo; // TODO: Cover this error case
  }

  async getOwnerInformation(property: DatafinitiProperty) {
    const { transactions = [] } = property || {};
    const lastSold = getLastSoldTransaction(transactions as Transaction[]);
    const { buyerFirstName, buyerLastName, contactOwnerMailAddressFull } =
      lastSold[0] || {};

    return {
      owner: {
        first: buyerFirstName || '',
        last: buyerLastName || '',
        address: contactOwnerMailAddressFull || '',
      },
    };
  }

  async getMortgageInformation(property: DatafinitiProperty) {
    if (!property) return {};

    const { title, transactions = [] } = property;
    const lastSold = getLastSoldTransaction(transactions as Transaction[]);
    const { loanAmount, lenderName, mortgageTerm } = lastSold[0] || {};
    const mortgageData = {
      mortgage: {
        lender: lenderName || '',
        titleCompany: title || '',
        amount: loanAmount || 0,
        term: mortgageTerm || '',
      },
    };

    return mortgageData;
  }
}
