import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Input,
} from '@angular/core';
import { Chart, ChartItem } from 'chart.js';
import {
  DEFAULT_CHART_BACKGROUNDS,
  DEFAULT_CHART_BORDERS,
  DEFAULT_CHART_LABELS,
  DEFAULT_CHART_BAR_OPTIONS,
  DEFAULT_CHART_PIE_OPTIONS,
} from './chart-defaults';
import { CHART_TYPE } from './chart.constants';
import { registerChartPlugins } from './chart.registration';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() title: string = '';
  @Input() chartType: CHART_TYPE = CHART_TYPE.PIE;
  @Input() chartData: number[] = [];
  @Input() chartBackgrounds: string[] = DEFAULT_CHART_BACKGROUNDS;
  @Input() chartBorders: string[] = [];
  @Input() chartLabels: string[] = [];
  @Input() chartOptions: Record<string, any> = {};
  @ViewChild('myChart', { static: false })
  myChart: ElementRef<HTMLCanvasElement> | undefined;

  public chart: Chart | undefined;
  public isBar: boolean | undefined;

  constructor() {
    registerChartPlugins();
  }

  updateChart(newData: number[]) {
    if (this.chart) {
      this.chart.data.datasets[0].data = newData;
      this.chart.update();
    }
  }

  ngOnInit() {
    this.chartType = this.chartType || CHART_TYPE.PIE;
    this.isBar = this.chartType === CHART_TYPE.BAR;
    this.title = this.title || 'Net Profit';
    this.chartData = this.chartData || [1, 2, 3];
    this.chartBackgrounds = this.chartBackgrounds;
    this.chartBorders = this.chartBorders || [DEFAULT_CHART_BORDERS[0]]; // only using green atm
    this.chartLabels = this.chartLabels || DEFAULT_CHART_LABELS;

    if (this.chartType === CHART_TYPE.PIE) {
      this.chartOptions = DEFAULT_CHART_PIE_OPTIONS;
    } else {
      this.chartOptions = DEFAULT_CHART_BAR_OPTIONS;
      this.chartOptions['scales'] = {
        y: {
          beginAtZero: false,
          ticks: {
            callback: (label: number) =>
              '$' + label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          },
        },
      };
    }
  }

  ngAfterViewInit() {
    this.chart = new Chart(
      this.myChart?.nativeElement.getContext('2d') as ChartItem,
      {
        type: this.chartType,
        data: {
          labels: this.chartLabels,
          datasets: [
            {
              label: this.title,
              data: this.chartData,
              backgroundColor: this.chartBackgrounds,
              borderColor: this.chartBorders,
              borderWidth: 1,
            },
          ],
        },
        options: this.chartOptions,
      }
    ) as Chart;
  }
}
