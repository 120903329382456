import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { InteractiveMapComponent } from './interactive-map/interactive-map.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [CommonModule, GoogleMapsModule, MatCardModule],
  declarations: [InteractiveMapComponent],
  exports: [InteractiveMapComponent],
})
export class InteractiveMapModule {}
