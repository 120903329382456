<section class="ComparablesFilter p-4">
  <h1>Comparables Advanced Filter</h1>
  <p>
    Comparables will, by default, use the values of your current property. The
    filters below allow you to customize the comps search further.
  </p>
  <!-- Form Fields -->
  <form [formGroup]="filterForm" class="d-flex flex-wrap">
    @for (filterName of filterControlData; track filterName) {
      @if (filterName.name === 'dateRange') {
        <div class="ComparablesFilter__RadioGroupWrapper">
          <mat-label
            class="ComparablesFilter__RadioGroupLabel"
            [innerHTML]="filterName.label"
          ></mat-label
          ><br />
          <mat-radio-group
            [formControlName]="filterName.name"
            class="ComparablesFilter__RadioGroup"
            aria-label="Select an option for oldest comp"
          >
            @for (
              comparablesRangeOption of comparablesRangeOptions;
              track comparablesRangeOption
            ) {
              <mat-radio-button [value]="comparablesRangeOption.value"
                >{{ comparablesRangeOption.label }}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      } @else if (filterName.name === 'maxYearBuilt') {
        <div class="ComparablesFilter__RangeWrapper">
          <div class="ComparablesFilter__Section">
            <label class="ComparablesFilter__SectionLabel">Built After</label>
            <span [innerHTML]="filterForm.value.minYearBuilt"></span>
          </div>
          <div class="ComparablesFilter__Section">
            <mat-slider
              [min]="defaultMinYearBuilt"
              [max]="currentYear"
              showTickMarks
            >
              <input formControlName="minYearBuilt" matSliderStartThumb />
              <input formControlName="maxYearBuilt" matSliderEndThumb />
            </mat-slider>
          </div>
          <div class="ComparablesFilter__Section">
            <label class="ComparablesFilter__SectionLabel">Built Before</label>
            <span [innerHTML]="filterForm.value.maxYearBuilt"></span>
          </div>
        </div>
      } @else if (filterName.name !== 'minYearBuilt') {
        <mat-form-field
          class="ComparablesFilter__Field"
          [ngClass]="{ 'ComparablesFilter__Field--Max': $index % 2 !== 0 }"
        >
          <mat-label
            class="ComparablesFilter__Label"
            [innerHTML]="filterName.label"
          ></mat-label>

          @if (filterName.name === 'propertyType') {
            <mat-select [formControlName]="filterName.name" multiple>
              @for (propertyType of propertyTypes; track propertyType) {
                <mat-option [value]="propertyType">{{
                  propertyType
                }}</mat-option>
              }
              <!-- Using innerHTML for mat-option breaks it -->
            </mat-select>
          } @else {
            <input
              [name]="filterName.name"
              [type]="filterName.name === 'zip' ? 'text' : 'number'"
              matInput
              [formControlName]="filterName.name"
            />
          }
        </mat-form-field>
      }
    }
  </form>

  <!-- Action Row-->
  <div class="row">
    <div class="col p-2 d-flex justify-content-end">
      <button
        class="ComparablesFilter__Button"
        mat-raised-button
        (click)="clearFilter()"
        [innerHTML]="'Clear Form'"
      ></button>
      <button
        class="ComparablesFilter__Button"
        mat-raised-button
        (click)="cancel()"
        [innerHTML]="'Cancel'"
      ></button>
      <button
        class="ComparablesFilter__Button"
        mat-raised-button
        color="primary"
        (click)="saveFilter()"
        [innerHTML]="'Apply'"
      ></button>
    </div>
  </div>
</section>
