import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoiService } from '@smartflip/ui-utils';
import { FieldConfig } from '@smartflip/data-models';
import { repairFields } from '@smartflip/data-field-configuration';
import { PropertyService } from '@smartflip/helper-utils';
import { RepairDetailCostsComponent } from '../repair-detail-costs/repair-detail-costs.component';

@Component({
  selector: 'app-repair-costs',
  templateUrl: './repair-costs.component.html',
  styleUrls: ['./repair-costs.component.scss'],
})
export class RepairCostsComponent implements OnInit {
  total: number;
  repairForm: UntypedFormGroup;
  repairDetailsForm: UntypedFormGroup;
  fieldList: FieldConfig[];
  details: UntypedFormArray;
  detailsExist: boolean;
  objectKeys = Object.keys;

  onSubmit(e) {}

  public showDetails() {
    const dialogWindow = this.diag.open(RepairDetailCostsComponent, {
      panelClass: 'RepairCostsModal',
    });

    dialogWindow
      .afterClosed()
      .subscribe((newData: { total: number; details: any[] }) => {
        if (newData) {
          // TODO: Improve this check with propertyID. Can't risk updating the wrong property
          this.propertyService.updateProperty(
            'repairdetail',
            newData.details,
            this.propertyService.propertyId
          );
          this.repairForm.patchValue({ total: newData.total });
          this.roi.calculate();
        }
      });
  }

  constructor(
    public dialog: MatDialog,
    private propertyService: PropertyService,
    private roi: RoiService,
    private diag: MatDialog
  ) {}

  ngOnInit() {
    this.fieldList = repairFields;
    this.repairForm = this.roi.formGroups['repair'];
    this.repairDetailsForm = this.roi.formGroups['repairdetail'];
    this.details = this.repairDetailsForm.get('details') as UntypedFormArray;
    this.detailsExist = this.details && this.details.controls.length > 0;
    // TODO: Fix this. We shouldn't need to copy total from the form. Just use it.
    const total = this.repairForm.get('total');
    const startVal = total.value || 0;

    this.total = startVal;

    total.valueChanges.subscribe((newTotal) => {
      this.total = newTotal;
    });
  }
}
