import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { sellingFields } from '@smartflip/data-field-configuration';
import { FieldConfig } from '@smartflip/data-models';
import { RoiService } from '@smartflip/ui-utils';

@Component({
  selector: 'app-selling-costs',
  templateUrl: './selling-costs.component.html',
  styleUrls: ['./selling-costs.component.scss'],
})
export class SellingCostsComponent implements OnInit {
  total: number;
  sellingForm: UntypedFormGroup;
  fieldList: FieldConfig[];
  objectKeys = Object.keys;

  onSubmit(e) {}

  constructor(private roi: RoiService) {}

  ngOnInit() {
    this.fieldList = sellingFields;
    this.total = 0;
    this.sellingForm = this.roi.formGroups['selling']; // this.fm.createControl(this.fieldList);
  }
}
