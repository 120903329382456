import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService, RoiService } from '@smartflip/ui-utils';
import { repairDetailFields } from '@smartflip/data-field-configuration';

@Component({
  selector: 'app-repair-detail-costs',
  templateUrl: './repair-detail-costs.component.html',
  styleUrls: ['./repair-detail-costs.component.scss'],
})
export class RepairDetailCostsComponent implements OnInit {
  fieldList: FieldConfig[];
  repairDetailForm: UntypedFormGroup;
  objectKeys = Object.keys;

  constructor(
    public roi: RoiService,
    private frm: FormService,
    public thisModalRef: MatDialogRef<RepairDetailCostsComponent>
  ) {}

  onSubmit(e) {}

  get total() {
    const details = this.repairDetailForm.get('details');

    if (details?.value.length > 0) {
      return details.value.reduce((total, item) => total + item.cost, 0);
    } else return 0;
  }

  save() {
    const details = this.repairDetailForm.value;

    this.thisModalRef.close({ total: this.total, details });
  }

  close() {
    this.thisModalRef.close(null);
  }

  addCost() {
    const newCost = this.frm.createControl([
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
      },
      {
        type: 'input',
        label: 'Cost',
        inputType: 'text',
        name: 'cost',
      },
    ]);
    (this.repairDetailForm.get('details') as UntypedFormArray).push(newCost);
  }

  ngOnInit() {
    this.fieldList = repairDetailFields;
    this.repairDetailForm = this.roi.formGroups['repairdetail'];
  }
}
