import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatafinitiService } from './datafiniti.service';

@NgModule({
  imports: [CommonModule],
})
export class DataServicesDatafinitiModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: DataServicesDatafinitiModule,
      providers: [
        DatafinitiService,
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
