import { Component, OnInit } from '@angular/core';
import { budgetMetadata } from '@smartflip/data-constants';
import { PropertyService } from '@smartflip/helper-utils';
import { RoiService } from '@smartflip/ui-utils';

interface budget {
  title: string;
  name: string;
  abbr: string;
}
@Component({
  selector: 'app-budget-panel',
  templateUrl: './budget-panel.component.html',
  styleUrls: ['./budget-panel.component.scss'],
})
export class BudgetPanelComponent implements OnInit {
  budgets: budget[];
  includeRepairInLoan = true;

  constructor(
    public roi: RoiService,
    private propertyService: PropertyService
  ) {}

  ngOnInit() {
    this.budgets = budgetMetadata;
    this.includeRepairInLoan =
      this.roi.formGroups.repair.controls['includeInLoan']?.value;
  }

  // Exclude or include repair costs with the loan
  public toggleRepair() {
    const propertyId = this.propertyService.propertyId;

    this.includeRepairInLoan = !this.includeRepairInLoan;
    // Update the form as it is used in the calculator
    this.roi.formGroups.repair.controls['includeInLoan']?.setValue(
      this.includeRepairInLoan
    );
    this.propertyService.updateProperty(
      'repair',
      { includeInLoan: this.includeRepairInLoan },
      propertyId
    );
  }
}
