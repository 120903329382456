import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  getAuth,
  // indexedDBLocalPersistence,
  // initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideFirestore, initializeFirestore } from '@angular/fire/firestore';
import { provideAnalytics, initializeAnalytics } from '@angular/fire/analytics';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import * as Sentry from '@sentry/angular';
import { MaterialModule } from '@smartflip/util-import-helper';
import { FeaturesAccountDeletionConfirmationModalModule } from '@smartflip/feat-account-deletion-modal';
import { StripePaymentModule, StripePaymentService } from '@smartflip/stripe';
import { FeaturesLoadingIndicatorModule } from '@smartflip/loading-indicator';
import { DataServicesDatafinitiModule } from '@smartflip/datafiniti';
import { ServicesHelperUtilsModule } from '@smartflip/helper-utils';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckBoxComponent } from '../../../../libs/features/feat-form-fields/src/lib/check-box/check-box.component';
import { RadioButtonComponent } from '../../../../libs/features/feat-form-fields/src/lib/radio-button/radio-button.component';
import { CustomCurrencyPipe } from './shared/pipes/custom-currency.pipe';

import { LenderFormComponent } from './views/lender/lender-form/lender-form.component';
import { HomeListComponent } from './views/home-list/home-list.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { AvatarComponent } from './shared/components/avatar/avatar.component';
import { PropertyDetailsModule } from './views/property-details/property-details.module';
import { SharedModule } from './shared/shared.module';
import { HomeListCardComponent } from './views/home-list/home-list-card/home-list-card.component';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { LoginEmailFormComponent } from './views/login/login-email-form/login-email-form.component';

@NgModule({
  providers: [
    provideFirebaseApp(() =>
      initializeApp(environment.firebase, 'The Smart Flip')
    ),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
    ),
    provideAnalytics(() => initializeAnalytics(getApp())),
    provideAuth(() => getAuth()
      // initializeAuth(getApp(), {
      //   persistence: indexedDBLocalPersistence,
      // })
    ), // only needed for auth features,
    provideStorage(() => getStorage()), // only needed for storage features
    CurrencyPipe,
    TitleCasePipe,
    ScreenOrientation,
    StripePaymentService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PropertyDetailsModule,
    SharedModule,
    DataServicesDatafinitiModule.forRoot(environment),
    ServicesHelperUtilsModule.forRoot(environment),
    StripePaymentModule.forRoot(environment),
    GoogleMapsModule,
    IonicModule.forRoot(),
    FeaturesLoadingIndicatorModule,
    FeaturesAccountDeletionConfirmationModalModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CheckBoxComponent,
    RadioButtonComponent,
    CustomCurrencyPipe,
    LenderFormComponent,
    HomeListComponent,
    MenuComponent,
    AvatarComponent,
    HomeListCardComponent,
    LoginEmailFormComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
