import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { otherFields } from '@smartflip/data-field-configuration';
import { FieldConfig } from '@smartflip/data-models';
import { RoiService } from '@smartflip/ui-utils';

@Component({
  selector: 'app-other-costs',
  templateUrl: './other-costs.component.html',
  styleUrls: ['./other-costs.component.scss'],
})
export class OtherCostsComponent implements OnInit {
  total: number;
  otherForm: UntypedFormGroup;
  fieldList: FieldConfig[];
  objectKeys = Object.keys;

  onSubmit(e) {}

  constructor(private roi: RoiService) {}

  ngOnInit() {
    this.fieldList = otherFields;
    this.total = 0;
    this.otherForm = this.roi.formGroups['other'];
  }
}
