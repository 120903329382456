<mat-form-field
  class="demo-full-width margin-top"
  [id]="field.name"
  [formGroup]="group"
>
  <mat-label
    class="ComparablesFilter__Label"
    [innerHTML]="field.label"
  ></mat-label>

  <mat-select
    [placeholder]="field.label || ''"
    [formControlName]="field.name || 'noName'"
    [multiple]="field.multiple"
  >
    @for (item of getFieldOptions(field); track item) {
    <mat-option [value]="item">{{ item }} </mat-option>
    }
  </mat-select>
</mat-form-field>
