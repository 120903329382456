import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlanOption } from '../stripe.constants';
import { StripePaymentService } from '../stripe-payment.service';
import { FacebookWebEventsService } from '../../../../facebook-events/src/lib/facebook-web-events.service';

@Component({
  selector: 'app-stripe-payment-form',
  templateUrl: './stripe-payment-form.component.html',
  styleUrls: ['./stripe-payment-form.component.scss'],
})
export class StripePaymentFormComponent {
  public activePlanDescription: string = '';
  public error: string = '';
  public plans: PlanOption[] = [];
  public paymentForm: FormGroup;
  public processingPayment$ = this.stripePaymentService.processing$;
  public showSubscribe: boolean = false;

  constructor(
    private fb: FormBuilder,
    private faceBookEventService: FacebookWebEventsService,
    private router: Router,
    private stripePaymentService: StripePaymentService
  ) {
    this.paymentForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      plan: ['1', [Validators.required, Validators.minLength(2)]],
      amount: [8, [Validators.required]],
    });
    this.stripePaymentService.startDataListeners();
    this.stripePaymentService.plans$.subscribe((plans) => (this.plans = plans));
    this.stripePaymentService.showSubscribe$.subscribe(
      (showIt) => (this.showSubscribe = showIt)
    );
    this.stripePaymentService.$activePlanDescription.subscribe((newMessage) => {
      this.activePlanDescription = newMessage;
    });
  }

  public close(): void {
    this.router.navigate([{ outlets: { modal: null } }]);
  }

  public handlePaymentClicked(event: MouseEvent): void {
    if (this.paymentForm?.valid) {
      // the value will be the price ID
      const subscriptionData = { price: this.paymentForm?.get('plan')?.value };

      this.faceBookEventService.logEvent('InitiateCheckout');
      this.stripePaymentService.subscribeToPlan(event, subscriptionData);
    } else {
      // This shouldn't happen given the pay button is disabled
      console.log(this.paymentForm);
    }
  }

  navigateHome() {
    this.router.navigate(['/home/properties']);
  }

  public modifyPlan() {
    this.stripePaymentService.modifySubscription();
  }
}
