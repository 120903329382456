import { Injectable, inject } from '@angular/core';
import {
  Firestore,
  deleteDoc,
  doc,
  docData,
  setDoc,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { SmartFlipUser } from '@smartflip/data-models';
import { FirebaseAnalyticsService } from '@thesmartflip/data-firebase';
import { AuthService } from '@smartflip/authentication';
import { parseJwt } from '../user.service.helper';
import { Logging } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedInUser: BehaviorSubject<SmartFlipUser> = new BehaviorSubject(
    null as unknown as SmartFlipUser
  );
  loggedInUser$: Observable<SmartFlipUser> = this
    .loggedInUser as Observable<SmartFlipUser>;
  currentUserId: string = '';

  private angularFirestore: Firestore = inject(Firestore);

  constructor(
    private authService: AuthService,
    private router: Router,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private logger: Logging
  ) {
    this.authService.authState$.subscribe((authState) => {
      if (authState) {
        const { displayName, email, uid } = authState;
        const [firstName, lastName] = displayName?.split(' ') || ['', ''];

        Sentry.setUser({ email: email || 'unknown user' });
        this.firebaseAnalyticsService.setUserId({ userId: uid });
        this.firebaseAnalyticsService.logEvent({
          name: 'login',
          params: { userId: uid },
        });
        this.currentUserId = uid;
        this.updateUserData({
          firstName,
          lastName,
          fullName: displayName || '',
          email: email || '',
        });
        this.setLoggedInUser(uid);

        this.logger.trace(`User logged in, ${uid} ${email}`);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  // Returns current fB user
  get currentUser(): any {
    return this.authService.authenticated
      ? this.authService.auth.currentUser
      : null;
  }

  // pull the user's metadata from FB and set service property
  private async setLoggedInUser(uid: string): Promise<void> {
    if (!uid) return;

    const path = `Users/${uid}`; // Endpoint on firebase
    const userRef = doc(this.angularFirestore, path);

    docData(userRef).subscribe(this.patchUserDefaults.bind(this));
  }

  // patches data to the user's metadata in firebase
  updateUserData(newData: SmartFlipUser): Promise<any> {
    if (!this.currentUser) return new Promise((res) => null);
    const path = `Users/${this.currentUserId}`; // Endpoint on firebase
    const userAsObject = { ...newData };
    const userRef = doc(this.angularFirestore, path);

    this.logger.trace(`User updated, ${JSON.stringify(userAsObject)}`);

    return setDoc(userRef, userAsObject, { merge: true });
  }

  private patchUserDefaults(loggedInUserSnapshot: unknown): void {
    const loggedInUser: SmartFlipUser = loggedInUserSnapshot as SmartFlipUser;

    if (!loggedInUser) return;

    loggedInUser.id = this.currentUserId;
    loggedInUser.defaults = {
      state: '',
      ...loggedInUser.defaults,
    };
    this.loggedInUser.next(loggedInUser);
  }

  public async deleteUser() {
    const userDocument = doc(
      this.angularFirestore,
      `Users/${this.currentUserId}`
    );
    const user = await this.authService.auth.currentUser;
    let documentDeleteResults;
    let userDeleteResults;

    try {
      documentDeleteResults = await deleteDoc(userDocument);
      this.logger.trace(`User deleted, ${user?.uid}`);
    } catch (err) {
      console.log(err);

      return err;
    }

    try {
      const token = (await user?.getIdToken()) || '';
      const tokenData = parseJwt(token);
      const provider = tokenData.firebase.sign_in_provider;
      const providerName = provider.split('.')[0];

      await this.authService.login(providerName);
      userDeleteResults = user?.delete();
    } catch (err) {
      console.log(err);

      return err;
    }

    return true; // success
  }
}
