<div class="HomeList">
  <div
    class="HomeList__Alert alert alert-primary d-flex"
    role="alert"
    [ngClass]="{ 'HomeList--Hidden': !showBanner }"
  >
    <span>The free tier allows for only one property at a time. <br />
      <a routerLink="/home/subscribe">Upgrade</a>
      to one of professional plans to add more properties.
    </span>
    <button
      type="button"
      class="close"
      (click)="hideBanner()"
      data-dismiss="alert"
      aria-label="Close Alert"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <span class="HomeList__ButtonWrapper">
    <div class="HomeList__ButtonColumn">
      <button
        mat-button
        mat-raised-button
        color="primary"
        id="add-new"
        class="HomeList__Button"
        (click)="newProperty()"
        [disabled]="disableAddNewButton"
      >
        Add New Property
      </button>
      <small
        class="HomeList__ButtonWarning"
        *ngIf="disableAddNewButton"
        [innerHTML]="subscriptionWarningText"
      ></small>
    </div>
    
    <div class="HomeList__ButtonColumn HomeList__ButtonColumn--Left">
      <button
        mat-button
        mat-raised-button
        color="primary"
        id="add-new"
        class="HomeList__Button"
        (click)="exportData()"
        [disabled]="disableAddNewButton"
      >
        Export Property Data
      </button>
    </div>
  </span>

  <app-loading *ngIf="loading"></app-loading>

  <ng-container *ngIf="properties$ | async; let properties">
    <ng-container *ngFor="let category of ['potential', 'active', 'sold']">
      <h3 class="HomeList__Title">
        {{ category }}
        <span class="HomeList__Hint">({{ cats[category].hint }})</span>
      </h3>
      <ng-container *ngFor="let property of properties[category]">
        <app-home-list-card
          [property]="property"
          class="HomeList__Card"
        ></app-home-list-card>
      </ng-container>
      <mat-card
        *ngIf="noProperties(properties[category])"
        class="HomeList__Card"
      >
        <p>
          You have no {{ category }} properties.
          {{ cats[category].howToAdd }} to get started.
        </p>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
