export class Upload {
    $key: string;
    propertyId: string; // Which house is this for?
    user: string; // Firebase User ID
    file: File;
    url: string; // url to FB storage
    name: string;
    progress: number;
    createdAt: Date = new Date();

    constructor(file: File) {
        this.file = file;
        this.name = file.name;
    }
}
