import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RoiService } from '@smartflip/ui-utils';
import { FieldConfig } from '@smartflip/data-models';
import { carryFields } from '@smartflip/data-field-configuration';

@Component({
  selector: 'app-carrying-costs',
  templateUrl: './carrying-costs.component.html',
  styleUrls: ['./carrying-costs.component.scss'],
})
export class CarryingCostsComponent implements OnInit {
  oldVals: Record<string, any> = {};
  total: number;
  duration: number;
  carryForm: UntypedFormGroup;
  fieldList: FieldConfig[];
  durationField: FieldConfig;
  objectKeys = Object.keys;

  onSubmit(e) {}

  calcMonthly(f) {
    return (this.carryForm.value[f.name] || 0) / this.duration;
  }

  constructor(private roi: RoiService) {}

  ngOnInit() {
    this.durationField =
      carryFields.filter((f) => f.name === 'duration')[0] || null;
    this.fieldList = carryFields.filter((f) => f.name !== 'duration');
    // isolate the duration because it doesn't have a monthly/total setup
    this.total = 0;
    this.duration = 6;
    this.carryForm = this.roi.formGroups['carry'];

    // Annual property tax total
    if (
      !this.carryForm.value['proptax'] ||
      this.carryForm.value['proptax'] === 0
    ) {
      // tslint:disable-next-line: no-string-literal
      const newTax =
        this.roi.calculationValues['summary']['price'].value * 0.02; // estimate  at 2%
      this.carryForm.patchValue({ proptax: newTax });
    }
  }
}
