export const convertPointStringToLatLong = (point: string): number[] => {
  if (!point) return [];

  return point
    .replace('(', '')
    .replace(')', '')
    .split(' ')
    .slice(1)
    .map((num) => parseFloat(num))
    .reverse();
};
