import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '@smartflip/authentication';
import { signInWithEmailAndPassword } from '@angular/fire/auth';
import { FirebaseError } from '@angular/fire/app';
import { PRIVACY_POLICY_URL } from '@smartflip/data-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public privacyPolicyUrl = PRIVACY_POLICY_URL;

  public loginForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(public authService: AuthService, private router: Router) {
    if (this.authService.authenticated) {
      this.router.navigate(['/home']);
    }
  }
  emailLogin() {
    const { email, password } = this.loginForm.value;

    this.login(email, password).then((user) => {
      if (user) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  async login(email: string, password: string) {
    const result = await signInWithEmailAndPassword(
      this.authService.auth,
      email,
      password
    );

    return result;
  }

  googleLogin() {
    const _this = this;
    this.authService
      .login('google')
      .then((user) => {
        _this.router.navigate(['/home']);
      })
      .catch((error) => {
        switch (error) {
          case 7:
            // Internet issue. Alert the user
            break;

          default:
            break;
        }
      });
  }

  facebookLogin() {
    this.authService
      .login('facebook')
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch((error: FirebaseError) => {
        if (
          error.message.includes(
            'Error: An account already exists with the same email'
          )
        ) {
          console.log(error.message);
          // Prompt the user
        }
      });
  }

  appleLogin() {
    this.authService
      .login('apple')
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch((error) => {
        console.log(error);

        if (
          error &&
          error.message &&
          error.message.includes(
            'Error: An account already exists with the same email'
          )
        ) {
          // Prompt the user
        }
      });
  }
}
