import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RoiService } from '@smartflip/ui-utils';
import { FieldConfig } from '@smartflip/data-models';
import { acquisitionFields } from '@smartflip/data-field-configuration';

@Component({
  selector: 'app-acquisition-costs',
  templateUrl: './acquisition-costs.component.html',
  styleUrls: ['./acquisition-costs.component.scss'],
})
export class AcquisitionCostsComponent implements OnInit {
  total: number;
  acquisitionForm: UntypedFormGroup;
  fieldList: FieldConfig[];
  objectKeys = Object.keys;

  onSubmit(e) {}

  constructor(private roi: RoiService) {}

  ngOnInit() {
    this.total = 0;
    this.acquisitionForm = this.roi.formGroups['acq'];
    this.fieldList = acquisitionFields;
  }
}
