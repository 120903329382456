export const comparablesColumns = [
  'salesStatus',
  'street',
  'distanceFromSource',
  'lastSoldPrice',
  'finishedSqFt',
  'bedrooms',
  'bathrooms',
  'selectBox',
];

export const comparablesHiddenColumns = [
  'finishedSqFt',
  'lastSoldPrice',
  'bedrooms',
  'distanceFromSource',
];
