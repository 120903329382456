export const comparablesInstructions =
  'Click the "Pull Comps" button to list comparables for this property.';

export const pullCompsButtonText = 'Pull comparables';

export const refreshCompsButtonText = 'Refresh comparables';

export const calculateARVButtonText = 'Estimate ARV';

export const enterAddressWarning =
  'Please enter address and zip for the property to continue';

export const selectCompsForARVText = 'Select to Estimate ARV';

export const arvUpdatedMessage =
  'ARV has been updated. See the Cost Details tab -> Property Details -> Sell Price';

export const comparablesWarningMessage =
  'Sorry, there were no comparable homes found for this property. Please check the address and try again.';

export const statusIconMap = {
  'For Sale': 'house',
  'Coming Soon': 'schedule',
  'Off Market': 'hide_source',
  Sold: 'check2-square',
  Pending: 'pending',
};

export const defaultMinYearBuilt = 1850;
