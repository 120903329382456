import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperPhoto } from '@smartflip/data-models';

@Component({
  selector: 'app-photo-carousel-modal',
  templateUrl: './photo-carousel-modal.component.html',
  styleUrls: ['./photo-carousel-modal.component.scss'],
})
export class PhotoCarouselModalComponent implements OnInit {
  public photos: SwiperPhoto[] = [];
  private activeSlideIndex: number = 0;

  constructor(
    private dialogRef: MatDialogRef<PhotoCarouselModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { photos: SwiperPhoto[] }
  ) {}

  public closeModal(selectedImgUrl?: string) {
    this.dialogRef.close(selectedImgUrl);
  }

  public selectForThumbnail() {
    this.closeModal(this.photos[this.activeSlideIndex].url);
  }

  public updateActiveIndex(sliderIndex: number) {
    this.activeSlideIndex = sliderIndex;
  }

  ngOnInit(): void {
    this.photos = this.data.photos;
  }
}
