<div class="Login">
  <ion-header>
    <mat-toolbar color="primary" class="Login__Header">
      <div class="Login__Logo"></div>
      <span class="fill-space"></span>
    </mat-toolbar>
  </ion-header>

  <div class="Login__Content container-fluid">
    <div class="Login__Welcome Login__Row row">
      <div class="col">
        <mat-card>
          <h2>Please log in to continue.</h2>
          <p>
            The Smart Flip requires you to log in so that your information will
            be there when you come back.
          </p>
          <p>
            We will never have access to your password or confidential
            information.
          </p>
        </mat-card>
      </div>
    </div>

    <div class="row Login__Row mt-1">
      <div class="col">
        <mat-card class="py-4">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <h6>Sign in with your provider of choice</h6>
                <button
                  mat-raised-button
                  color="primary"
                  class="Login__Button m-2"
                  (click)="googleLogin()"
                >
                  <i class="fab fa-google"></i>
                  <span [innerHTML]="' Sign in with Google'"></span>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <button
                  mat-raised-button
                  color="primary"
                  class="Login__Button m-2"
                  (click)="facebookLogin()"
                >
                  <i class="fab fa-facebook-f"></i>
                  <span [innerHTML]="' Sign in with Facebook'"></span>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="true">
              <div class="col text-center">
                <button
                  mat-raised-button
                  color="primary"
                  class="Login__Button m-2"
                  (click)="appleLogin()"
                >
                  <ion-icon name="logo-apple"></ion-icon>
                  <span [innerHTML]="' Sign in with Apple'"></span>
                </button>
              </div>
            </div>
            <smartflip-login-email-form
              [loginForm]="loginForm"
              (loginButtonClicked)="emailLogin()"
            ></smartflip-login-email-form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <footer class="Login__Footer">
    <mat-toolbar color="primary">
      <p>Copyright &#64;2024 The Smart Flip, LLC.</p>
      <p>
        <a
          class="Login__FooterLink"
          [href]="privacyPolicyUrl"
          [innerHTML]="'Privacy Policy'"
        ></a>
      </p>
    </mat-toolbar>
  </footer>
</div>
