export const facebookEventAccessToken =
  'EAAKB7iZCX2zwBOxZCsSoP6Gx9dVkYQ40ZBrWulplMqCtj7qDjz2LbByVS1QbbsTAbnsoXZA8MvaWgyAW0AtAZA4RSx40OWoQd69y6qDuBgPj1VQlcsscwjZAqnQkNp9kkWNZAiJmo8YYfzI4iJkJ9acnEZA10BZCTcMfz5e1wFSynWH1gPpipkgwoZB4CilKsLsPWKiAZDZD';

export const webPixelID = 462717499812265;

export const conversionApiVersion = 'v20.0';

export const defaultPayload = {
  event_name: 'Subscribe',
  event_time: +new Date(),
  event_id: 'Subscribe',
  action_source: 'website',
  user_data: {
    em: ['sample@example.com'],
    ph: [null],
  },
  custom_data: {
    currency: 'USD',
    predicted_ltv: '800',
    value: '49.99',
  },
};

export const subscribePayload = {
  ...defaultPayload,
};

export const checkoutPayload = {
  ...defaultPayload,
  event_name: 'InitiateCheckout',
  content_category: 'Checkout',
  content_ids: null,
  contents: 'StartingPayment',
  currency: 'USD',
  num_items: 1,
  value: 49.99,
  custom_data: {},
};

export const startTrialPayload = {
  ...defaultPayload,
  event_name: 'StartTrial',
};

export const viewContentPayload = {
  ...defaultPayload,
  event_name: 'ViewContent',
  content_ids: null,
  content_category: 'page',
  content_name: 'PageView',
  content_type: 'Page',
  custom_data: {},
};

export const schedulePayload = {
  ...defaultPayload,
  event_name: 'Schedule',
  custom_data: {
    event_date: null,
  },
};
