<p>Please fill out the form below to complete your subscription setup.</p>
<p class="StripeSubscriptionForm__Note">
  We do not store any payment information on our servers. Payments are processed
  through our partner <a href="https://stripe.com" target="_blank">Stripe</a>.
  <img
    class="StripeSubscriptionForm__StripeBadge"
    src="assets/img/Powered by Stripe - blurple.svg"
  />
</p>

<mat-card class="StripeSubscriptionForm__Card">
  <div
    class="StripeSubscriptionForm__Error"
    *ngIf="error"
    [innerHTML]="error"
  ></div>

  <form class="StripeElement" [formGroup]="paymentForm">
    <mat-form-field>
      <input
        matInput
        type="text"
        formControlName="firstName"
        placeholder="First Name"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        type="text"
        formControlName="lastName"
        placeholder="Last Name"
      />
    </mat-form-field>
    <br />
    <mat-form-field class="StripeSubscriptionForm__Email">
      <input matInput type="text" formControlName="email" placeholder="Email" />
    </mat-form-field>
    <mat-form-field class="StripeSubscriptionForm__Select">
      <mat-label for="planOptions">Subscription Plan</mat-label>
      <mat-select class="form-control" formControlName="plan">
        <mat-option value="1" disabled selected>Select a plan</mat-option>
        <mat-option *ngFor="let plan of plans" [value]="plan.id">{{
          plan.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <input type="hidden" name="amount" />

    <div class="d-flex row justify-content-end">
      <div class="d-flex p-2 col justify-content-end">
        <button
          class="StripeSubscriptionForm__Button"
          mat-button
          mat-raised-button
          type="cancel"
          [disabled]="processingPayment"
          (click)="close()"
        >
          Cancel
        </button>
        <button
          [disabled]="paymentForm.invalid || paymentForm.pristine"
          [ngClass]="{
            'StripeSubscriptionForm__Button--Primary':
              paymentForm.valid && paymentForm.dirty
          }"
          class="StripeSubscriptionForm__Button"
          mat-button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="pay($event)"
        >
          Upgrade
        </button>
      </div>
    </div>
  </form>
</mat-card>
