import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, filter } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { MatDialog } from '@angular/material/dialog';
import { Comparable, Property } from '@smartflip/data-models';
import { convertPointStringToLatLong } from '@smartflip/utils-data';
import { PropertyService } from '@smartflip/helper-utils';
import { filterControlMetadata } from '@smartflip/data-field-configuration';
import {
  ComparablesHelperService,
  MapLocation,
  shouldPullCompsFromFilters,
  updateFilters,
} from '@smartflip/interactive-map';
import { PullComparablesService } from './../../../../shared/services/pull-comparables.service';
import { ComparablesService } from '../../../../shared/services/comparables.service';
import {
  calculateARVButtonText,
  comparablesInstructions,
  comparablesWarningMessage,
  defaultMinYearBuilt,
  enterAddressWarning,
  pullCompsButtonText,
  refreshCompsButtonText,
  selectCompsForARVText,
} from './comparables.constants';
import { ComparablesFilterComponent } from './comparables-filter/comparables-filter.component';

@Component({
  selector: 'app-comparables',
  templateUrl: './comparables.component.html',
  styleUrls: ['./comparables.component.scss'],
})
export class ComparablesComponent implements OnInit {
  @Input() propertyIsSet: boolean;
  @Input() error: boolean;
  @Output() errorChange: EventEmitter<boolean> = new EventEmitter();

  public arvIsSet: boolean = false;
  public comparablesInstructions = comparablesInstructions;
  public comparablesWarningMessage = comparablesWarningMessage;
  public calculateARVButtonText = selectCompsForARVText;
  public compsList$: BehaviorSubject<Comparable[]> = new BehaviorSubject([]);
  public currentPropertyLabel: string;
  public compsUrl: SafeResourceUrl;
  public enterAddressWarning = enterAddressWarning;
  public errorText: string;
  public isNative: boolean = false;
  public loadingComps$: Observable<boolean> =
    this.pullCompsService.loadingComps$;
  public getCompsButtonText = pullCompsButtonText;
  public selectedComps: Comparable[] = [];
  public showMap: boolean = true;
  public showMapToggle: boolean = true;
  public currentFilters: any[];
  public highlightedAddress$: BehaviorSubject<string> = new BehaviorSubject('');
  public advancedMarkers: google.maps.marker.AdvancedMarkerElement[];
  property: Property;
  location = [0, 0];
  comparablesMarkers: MapLocation[];
  hoveredComp?: string;

  constructor(
    private comparablesService: ComparablesService,
    private comparablesHelperService: ComparablesHelperService,
    private pullCompsService: PullComparablesService,
    private modal: MatDialog,
    private propertyService: PropertyService,
  ) {}

  public async showFilter() {
    const existingFormFilters =
      (await this.propertyService.getProperty(this.propertyService.propertyId))
        .compsFilters || {};
    // Set defaults for the yearFilter Values
    existingFormFilters.minYearBuilt =
      existingFormFilters.minYearBuilt || defaultMinYearBuilt;
    existingFormFilters.maxYearBuilt =
      existingFormFilters.maxYearBuilt || new Date().getFullYear();

    const modal = this.modal.open(ComparablesFilterComponent, {
      data: existingFormFilters,
    });

    modal.afterClosed().subscribe(this.handleFilterComps.bind(this));
  }

  public async pullComps(optionalFilters) {
    if (!this.propertyIsSet) return;

    if (!optionalFilters) {
      optionalFilters =
        this.propertyService.currentProperty?.compsFilters || null;
    }

    const compsResults = await this.pullCompsService.pullComps(optionalFilters);

    this.setCurrentFilters(optionalFilters);
    this.comparablesService.setComps(compsResults);
    this.getCompsButtonText = refreshCompsButtonText;
  }

  public updateCompsSelected(selectedComps: SelectionModel<Comparable>): void {
    this.selectedComps = selectedComps.selected;
    this.calculateARVButtonText =
      this.selectedComps.length > 0
        ? calculateARVButtonText
        : selectCompsForARVText;
  }

  public highlightPropertyInList(marker: MapLocation) {
    this.highlightedAddress$.next(marker?.label || null);
  }

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      this.showMapToggle = false;
      this.showMap = false;
      this.isNative = true;
    }

    this.propertyService
      .getProperty(this.propertyService.propertyId)
      .then((property) => {
        this.property = property;
        this.location = convertPointStringToLatLong(property.gen.geoLocation);
        this.currentPropertyLabel = property.gen.address;
        this.setCurrentFilters(property.compsFilters);
      });

    this.comparablesService.init();
    this.comparablesService.comps$
      .pipe(filter((comp) => !!comp))
      .subscribe(this.setupComparables.bind(this));
    this.comparablesHelperService.advancedMarkers$.subscribe(
      (markers) => (this.advancedMarkers = markers),
    );
  }

  private setupComparables(comparablesList: Comparable[]) {
    this.compsList$.next(comparablesList);
    this.getCompsButtonText =
      comparablesList?.length > 0
        ? refreshCompsButtonText
        : pullCompsButtonText;
    this.comparablesHelperService
      .setComparablesMarkers(comparablesList, this.location)
      .then((newMakers) => this.handleMarkersSetup(newMakers));
  }

  private handleMarkersSetup(newMarkers) {
    const centerOfMap = {
      lat: this.location[0],
      lng: this.location[1],
    };

    this.comparablesMarkers = newMarkers;
    // pass along this information for later use in the component
    this.comparablesHelperService.createAdvancedMarkers(
      this.comparablesMarkers,
      centerOfMap,
    );
  }

  onHoverChange(comp?: Comparable) {
    this.advancedMarkers?.map((marker) => {
      marker.content['querySelector']('.highlighted')?.classList.remove(
        'highlighted',
      );

      const markerAddress =
        marker.content['querySelector']('.map-label').textContent;

      if (comp?.street === markerAddress) {
        marker.content['querySelector'](
          '.material-symbols-outlined',
        ).classList.add('highlighted');
      }
    });
  }

  // Show the filters previously selected if applicable
  // Filter values can be at least, string, number, string[] or date so using unknown here
  setCurrentFilters(
    filters: Record<keyof typeof filterControlMetadata, unknown> = null,
  ) {
    this.currentFilters = updateFilters(filters);
  }

  public toggleMap() {
    this.showMap = !this.showMap;
  }

  private handleFilterComps(filterParams: Record<string, number>) {
    const updatedParams = shouldPullCompsFromFilters(filterParams);
    const { minYearBuilt, maxYearBuilt } =
      (updatedParams as {
        minYearBuilt: any;
        maxYearBuilt: any;
      }) || {};
    const thisYear = new Date().getFullYear();

    if (!minYearBuilt || minYearBuilt === defaultMinYearBuilt) {
      delete updatedParams['minYearBuilt'];
    }

    if (!maxYearBuilt || maxYearBuilt === thisYear) {
      delete updatedParams['maxYearBuilt'];
    }

    // OK to set compsFilters to null or empty object
    this.propertyService.updateProperty(
      'compsFilters',
      updatedParams,
      this.propertyService.propertyId,
    );

    this.pullComps(updatedParams);
  }
}
