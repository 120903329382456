<div class="ProfitDetails">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="Header">Financial</h2>
        <p>Powerful insight into the profitability of your properties</p>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- <mat-card class="ProfitDetails__Card">
          <smartflip-financial-aggregates></smartflip-financial-aggregates>
        </mat-card> -->

        <mat-card class="ProfitDetails__Card">
          <h2>Profit Percentage</h2>
          <h5>
            Costs vs profit (if sold today)<br />
            as a % of {{ sellingPrice | currency : 'USD' : 'symbol' : '1.0-0' }}
          </h5>
          <p *ngIf="purchasePrice <= 0; else cost_pie_chart">
            <em [innerHTML]="missingDataText"></em>
          </p>
          <ng-template #cost_pie_chart>
            <app-chart
              [chartData]="profitData"
              [chartLabels]="profitLabels"
              #piechart
            ></app-chart>
          </ng-template>
        </mat-card>

        <mat-card class="ProfitDetails__Card">
          <h2>Profit Over Time</h2>
          <h5>Profit Loss due to Carrying Costs Over Time</h5>
          <p
            *ngIf="
              purchasePrice <= 0 || sellingPrice <= 0;
              else profit_loss_chart
            "
          >
            <em [innerHTML]="missingDataText"></em>
          </p>
          <ng-template #profit_loss_chart>
            <app-chart
              [chartType]="chartTypeBar"
              [chartData]="carryingData"
              [chartLabels]="carryingLabels"
              [chartBackgrounds]="backgrounds"
              #linechart
            ></app-chart>
          </ng-template>
        </mat-card>
      </div>
    </div>
  </div>
</div>
