import { Injectable } from '@angular/core';
import { Comparable } from '@smartflip/data-models';
import { MapLocation } from '@smartflip/interactive-map';
import { convertPointStringToLatLong } from '@smartflip/utils-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { buildContent } from './comparables.helper';
import { Logging } from '@smartflip/helper-utils';

@Injectable({
  providedIn: 'root',
})
export class ComparablesHelperService {
  private comparablesMarkers: MapLocation[] = [];
  private advancedMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public advancedMarkers$: Subject<google.maps.marker.AdvancedMarkerElement[]> =
    new Subject();
  private mapCenterPosition: { lat: number; lng: number } = {
    lat: 0,
    lng: 0,
  };

  constructor(private logger: Logging) {}

  private markerHoverListener: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public markerHoverListener$: Observable<string | null> =
    this.markerHoverListener.asObservable();

  public updateHover(markerAddress: string | null): void {
    this.markerHoverListener.next(markerAddress);
  }
  private highlightedRowListener: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public highlightedRowListener$: Observable<string> =
    this.highlightedRowListener.asObservable();

  public showHoveredTableRow(markerToHighlight: string) {
    this.highlightedRowListener.next(markerToHighlight);
  }

  public setComparablesMarkers = async (
    comparablesList: Comparable[],
    locationArray: any[],
  ): Promise<MapLocation[]> => {
    let comparablesMarkers: MapLocation[] = [];

    if (comparablesList?.length > 0) {
      comparablesMarkers = comparablesList.map((comp) => {
        const [latitude, longitude] = convertPointStringToLatLong(
          comp.geoLocation || '',
        );

        return {
          latitude,
          longitude,
          label: comp.street,
          content: buildContent(comp.street, false),
          property: comp,
        } as MapLocation;
      });
      // Add the source property
      comparablesMarkers.unshift({
        label: '🏠',
        latitude: locationArray[0],
        longitude: locationArray[1],
        content: buildContent('source property', true),
      });
    }

    return comparablesMarkers;
  };

  public createAdvancedMarkers(
    comparablesMarkers: MapLocation[] = [],
    center: { lat: number; lng: number },
  ) {
    this.comparablesMarkers = comparablesMarkers;
    this.mapCenterPosition = center;
  }

  public async initMap() {
    const center = this.mapCenterPosition;
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    await google.maps.importLibrary('marker');
    const domMapElement = document.getElementById('google-map');

    if (!domMapElement) {
      this.logger.warn('No map element');

      return;
    }

    const googleMap = new Map(domMapElement, {
      mapId: 'CompsMap',
      center,
      zoom: 14,
    });
    this.advancedMarkers = [];
    const updatedAdvancedMarkers = this.comparablesMarkers.map((marker) => {
      const newMarker = new google.maps.marker.AdvancedMarkerElement({
        map: googleMap,
        content: marker.content,
        position: { lat: marker.latitude, lng: marker.longitude },
        title: null,
      });

      newMarker.addListener('click', () => {
        // TODO: select the comp in the table
        // this.updateHover(marker.property.street);
      });
      (newMarker?.content as unknown as Element)
        ?.querySelector('.smartflip-marker-icon')
        ?.addEventListener('mouseleave', () => {
          this.updateHover(null);
        });
      (newMarker?.content as unknown as Element)
        ?.querySelector('.smartflip-marker-icon')
        ?.addEventListener('mouseenter', () => {
          this.updateHover(marker.property?.street || null);
        });
      // TODO: possibly cleanup. I don't think we need this anymore
      return newMarker;
    });

    this.advancedMarkers = updatedAdvancedMarkers;
    this.advancedMarkers$.next(updatedAdvancedMarkers);
  }
}
