import { Component, Input } from '@angular/core';
import { Property } from '@smartflip/data-models';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-list-card',
    templateUrl: './home-list-card.component.html',
    styleUrls: ['./home-list-card.component.scss'],
})
export class HomeListCardComponent {
  @Input() property: Property;

  constructor(private router: Router) {}

  open(propertyId: string) {
      this.router.navigate(['/home', propertyId]);
  }
}
