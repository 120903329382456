import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { emptyAdditionalPropertyInfo } from '@smartflip/data-constants';
import { environment } from 'apps/app/src/environments/environment';
import {
  AttomOwnerResponseV1,
  AttomMortgageResponseV1,
  AttomAdditionalInfo,
} from '@smartflip/data-models';
import { catchError, firstValueFrom, of, timeout } from 'rxjs';
import { AttomPropertyV1 } from './attom.constants';

@Injectable({
  providedIn: 'root',
})
export class AttomService {
  private attomAPIUrlV1 = environment.attomApiUrlV1;
  private attomAPIKey = environment.attomApiKey;

  constructor(private http: HttpClient) {}

  async getPropertyByAddress(
    fullAddress: string
  ): Promise<AttomAdditionalInfo> {
    const compsURL = `${this.attomAPIUrlV1}property/detail?address=${fullAddress}`;
    const headers = new HttpHeaders({
      apikey: this.attomAPIKey,
    });
    const compData = (await firstValueFrom(
      this.http.get(encodeURI(compsURL), { headers }).pipe(
        timeout<any>(2000),
        catchError((_err) => of({} as AttomPropertyV1))
      )
    )) as AttomPropertyV1;

    if (!compData.property) return emptyAdditionalPropertyInfo;

    const { summary, building, lot, identifier } = compData.property[0];
    const {} = this.getOnwerInformation(identifier?.attomId);
    const addedInfo = {
      year: summary?.yearbuilt?.toString() || '',
      sqft: building?.size?.bldgsize?.toString() || '',
      lot: lot?.lotsize2?.toString() || '',
      attomId: identifier?.attomId || 0,
    };

    return addedInfo;
  }

  async getOnwerInformation(attomId: number) {
    if (!attomId) return {};

    const ownerURL = `${this.attomAPIUrlV1}property/detailowner?attomid=${attomId}`;
    const headers = new HttpHeaders({
      apikey: this.attomAPIKey,
    });
    const ownerDataResponse = (await firstValueFrom(
      this.http.get(encodeURI(ownerURL), { headers }).pipe(
        timeout<any>(2000),
        catchError((_err) => of({}))
      )
    )) as AttomOwnerResponseV1;

    if (!ownerDataResponse || Object.keys(ownerDataResponse).length === 0) {
      return { error: 'No owner data found' };
    }

    const { owner, building } = ownerDataResponse.property[0];
    const ownerData = {
      baths: building.rooms.bathstotal || 0,
      beds: building.rooms.beds || 0,
      owner: {
        first: owner.owner1?.firstnameandmi || '',
        last: owner.owner1?.lastname || '',
        address: owner.mailingaddressoneline || '',
      },
    };

    return ownerData;
  }

  async getMortgageInformation(attomId: number) {
    if (!attomId) return {};

    const mortgageURL = `${this.attomAPIUrlV1}property/detailmortgage?attomid=${attomId}`;
    const headers = new HttpHeaders({
      apikey: this.attomAPIKey,
    });
    const mortgageDataResponse = (await firstValueFrom(
      this.http.get(encodeURI(mortgageURL), { headers }).pipe(
        timeout<any>(2000),
        catchError((_err) => of({}))
      )
    )) as AttomMortgageResponseV1;

    if (
      !mortgageDataResponse ||
      Object.keys(mortgageDataResponse).length === 0
    ) {
      return { error: 'No mortgage data found' };
    }

    const { mortgage, building } = mortgageDataResponse.property[0];
    const mortgageData = {
      baths: building.rooms.bathstotal || 0,
      beds: building.rooms.beds || 0,
      mortgage: {
        lender: mortgage.lender.lastname || '',
        titleCompany: mortgage.title.companyname || '',
        amount: mortgage.amount || 0,
      },
    };

    return mortgageData;
  }
}
