<table
  mat-table
  matSort
  [dataSource]="compsTableSource"
  (matSortChange)="handleSortChange($event)"
  multiTemplateDataRows
  class="mat-elevation-z8"
>
  <ng-container
    matColumnDef="{{ column }}"
    *ngFor="let column of columnsToDisplay"
  >
    <th
      [ngClass]="{
        'CompsTable__Column--Right': alignRight(column),
        'CompsTable__Column--OverflowVisible': column === 'salesStatus'
      }"
      class="{{ 'CompsTable__Column--' + column }}"
      mat-header-cell
      mat-sort-header
      *matHeaderCellDef
    >
      @if (column === 'salesStatus') { @if (showStatusHeader) {
      <span [innerHTML]="headerLookup[column]"></span>&nbsp; }
      <smartflip-status-legend></smartflip-status-legend>
      } @else {
      <span [innerHTML]="headerLookup[column]"></span>
      }
    </th>
    <td
      [ngClass]="{ 'CompsTable__Column--Right': alignRight(column) }"
      mat-cell
      *matCellDef="let property; let index"
    >
      <ng-container *ngIf="column !== 'selectBox'; else checkboxColumn">
        @if (column === 'salesStatus') {
        <mat-icon [innerHTML]="getIcon(property[column])"></mat-icon>
        } @else {
        <span [innerHTML]="formatColumnData(column, property)"></span>}
      </ng-container>

      <ng-template #checkboxColumn>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? compSelection.toggle(property) : null"
          [checked]="compSelection.isSelected(property)"
          [aria-label]="checkboxLabel(property, index)"
        >
        </mat-checkbox>
      </ng-template>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="blankColumn">
    <td mat-cell *matCellDef></td>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let property"
      [attr.colspan]="columnsToDisplay.length - 1"
    >
      <div
        class="ComparablesTable__Detail"
        [@detailExpand]="
          property !== expandedElement ? 'collapsed' : 'expanded'
        "
      >
        <div class="CompsTable__Address">
          <div>
            <span class="ComparablesTable__FullAddress">
              {{ property.city }}, {{ property.state }}
              {{ property.zipcode }} </span
            ><br />
            @if(!showStatusHeader) {
            <span><b>Distance:&nbsp;</b></span>
            <span [innerHTML]="property.distanceFromSource"></span>mi<br />
            <span><b>Bedrooms:&nbsp;</b></span>
            <span [innerHTML]="property.bedrooms"></span>mi<br />
            }
            <span><b>Type:&nbsp;</b></span
            ><span [innerHTML]="property.propertyType"></span>
          </div>
        </div>
        <!-- Right Column -->
        <div class="CompsTable__MetaStack">
          <div class="CompsTable__Meta">
            <h6>Last Date Sold:&nbsp;</h6>
            <span [innerHTML]="property.lastSoldDate | date"></span>
          </div>
          <div class="CompsTable__Meta">
            <h6>Year Built:&nbsp;</h6>
            <span [innerHTML]="property.yearBuilt"></span>
          </div>
          <div class="CompsTable__Meta">
            <h6>Lot Size:&nbsp;</h6>
            <span [innerHTML]="property.lotSize"></span>
          </div>
          @if(!showStatusHeader) {
          <div class="CompsTable__Meta">
            <h6>Square Ft:&nbsp;</h6>
            <span [innerHTML]="property.finishedSqFt"></span>
          </div>
          <div class="CompsTable__Meta">
            <h6>Last Sold Price:&nbsp;</h6>
            <span [innerHTML]="property.lastSoldPrice"></span>
          </div>
          }
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let property; columns: columnsToDisplay"
    class="SummaryRow"
    [class.expandedRow]="expandedElement === property"
    [class.SummaryRow--Highlighted]="property?.street === highlightedAddress"
    (click)="expandedElement = expandedElement === property ? null : property"
    (mouseenter)="onHoverChange(property)"
    (mouseleave)="onHoverChange(null)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['blankColumn', 'expandedDetail']"
    class="detailRow"
  ></tr>
</table>
