import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddressQueryProps, DatafinitiProperty } from '@smartflip/data-models';
import { AttomResponseType } from '@smartflip/data-attom';
import { lastValueFrom, catchError, of } from 'rxjs';
import { getCompsFromResponse } from './helpers/comparables.service.helper';
import { createRequestBody } from './datafiniti.query';
import { getCompsFromDatafinitiResponse } from './helpers/comparables.service.datafiniti.helper';

export const getAttomComps = async (
  address: string,
  zip: string,
  attomAPIKey: string,
  attomAPIurl: string,
  http: HttpClient
) => {
  const city = '-';
  const state = '-';
  const params = `miles=10&lotSizeRange=20000&yearBuiltRange=50&sqFeetRange=2000`;
  const addressPath = `${address}/${city}/US/${state}/${zip}`;
  const compsURL = `${attomAPIurl}salescomparables/address/${addressPath}?${params}`;

  try {
    const headers = new HttpHeaders({
      apikey: attomAPIKey,
    });
    const compData = (await http
      .get(encodeURI(compsURL), { headers })
      .toPromise()) as AttomResponseType;

    return getCompsFromResponse(compData);
  } catch (e) {
    console.log(e);
    // TODO: allow comps to be [] or error object and update accordingly
    return null;
  }
};

export const getDatafinitiComps = async (
  sourceData: AddressQueryProps,
  datafinitiKey: string,
  datafinitiUrl: string,
  http: HttpClient
) => {
  const propertyURL = datafinitiUrl;
  const headers = new HttpHeaders({
    Authorization: `Bearer ${datafinitiKey}`,
    'Content-Type': 'application/json',
  });
  const json = createRequestBody('comp', sourceData);
  // const propertyDetail: DatafinitiProperty = mockProperties[1];
  const compsList = await lastValueFrom(
    http
      .post<{ records: DatafinitiProperty[] }>(encodeURI(propertyURL), json, {
        headers,
      })
      .pipe(
        catchError((e) => {
          console.log(e);
          return of({ records: [] });
        })
      )
  );

  return getCompsFromDatafinitiResponse(compsList, sourceData); // TODO: Cover this error case
};
