// Provide a facade for vendor data

import { Injectable } from '@angular/core';
import { AttomService } from '@smartflip/data-attom';
import {
  AddressQueryProps,
  DatafinitiProperty,
  GeneralAdditionalInfo,
  ServiceIds,
} from '@smartflip/data-models';
import { DatafinitiService } from '@smartflip/datafiniti';
import { AddressAbbreviationsService } from './address-abbreviations.service';

@Injectable({
  providedIn: 'root',
})
export class VendorDataService {
  private dataFinitiProperty!: DatafinitiProperty;

  constructor(
    private attomService: AttomService,
    private datafinitiService: DatafinitiService,
    private addressAbbreviationService: AddressAbbreviationsService
  ) {}

  async getPropertyByAddress(
    addressProps: AddressQueryProps,
    serviceId?: ServiceIds
  ): Promise<GeneralAdditionalInfo | null> {
    if (serviceId === 'attom') {
      const { address, city, province, zip } = addressProps;
      const addressString = `${address}, ${city}, ${province} ${zip}`;

      return this.attomService.getPropertyByAddress(addressString);
    } else {
      this.datafinitiService.datafinitiProperty.subscribe((property) => {
        const propertyWithUnabbreviatedAddress = {
          ...property,
          address: property
            ? this.addressAbbreviationService.unabbreviateAddress(
                property.address
              )
            : '',
        };
        this.dataFinitiProperty = propertyWithUnabbreviatedAddress;
      });

      const addressPropsWithAbbreviatedAddress = {
        ...addressProps,
        address: this.addressAbbreviationService.abbreviateAddress(
          addressProps.address
        ),
      };

      return this.datafinitiService.getPropertyByAddress(
        addressPropsWithAbbreviatedAddress
      );
    }
  }

  async getOwnerInformation(attomId?: number) {
    if (attomId) {
      return this.attomService.getOnwerInformation(attomId);
    } else {
      return this.datafinitiService.getOwnerInformation(
        this.dataFinitiProperty
      );
    }
  }

  async getMortgageInformation(attomId?: number) {
    if (attomId) {
      return this.attomService.getMortgageInformation(attomId);
    } else {
      return this.datafinitiService.getMortgageInformation(
        this.dataFinitiProperty
      );
    }
  }
}
