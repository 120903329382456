import { FieldConfig } from '@smartflip/data-models';

export const repairDetailFields: FieldConfig[] = [
  {
    type: 'formArray',
    name: 'details',
    subControls: [
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
      },
      {
        type: 'input',
        label: 'Cost',
        inputType: 'text',
        name: 'cost',
      },
    ],
  },
];
