import { Inject, Injectable, inject } from '@angular/core';
import {
  Functions,
  HttpsCallableResult,
  httpsCallable,
} from '@angular/fire/functions';
import { StripePrice, StripeProduct } from '@smartflip/data-models';
import { FirebaseService } from '@thesmartflip/data-firebase';
import { User } from '@firebase/auth-types';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Subject, BehaviorSubject } from 'rxjs';
import { PlanOption } from './stripe.constants';
import { FacebookWebEventsService } from '../../../facebook-events/src/lib/facebook-web-events.service';
import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  addDoc,
  and,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);
  private plans: BehaviorSubject<PlanOption[]> = new BehaviorSubject(
    [] as PlanOption[]
  );
  private productList: StripeProduct[] = [];
  private showSubscribe: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public $activePlanDescription: Subject<string> = new Subject<string>();
  public plans$ = this.plans.asObservable();
  public processing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly processing$ = this.processing.asObservable();
  public showSubscribe$ = this.showSubscribe.asObservable();

  constructor(
    private facebookWebEventsService: FacebookWebEventsService,
    private firebaseService: FirebaseService,
    @Inject('environment')
    private environment: any
  ) {}

  public async startDataListeners() {
    // Get all subscription plans products
    const productsCollection = collection(this.firestore, 'products');
    const productsQuery = query(
      productsCollection,
      and(where('active', '==', true), where('livemode', '==', true))
    );
    const productsSnapshot = await getDocs(productsQuery);

    productsSnapshot.forEach((product) =>
      this.initializeAvailablePlans(
        product as QueryDocumentSnapshot<DocumentData>
      )
    );
    this.getUserSubscriptions();
  }

  public async modifySubscription(): Promise<HttpsCallableResult<unknown>> {
    this.processing.next(true);
    const functionRef = httpsCallable(
      this.functions,
      'ext-firestore-stripe-payments-createPortalLink'
    );
    const functionPromise = functionRef({ returnUrl: window.location.origin });

    functionPromise
      .then((data: any) => {
        const { url } = data.data;

        window.open(url, '_blank');
        this.processing.next(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => this.processing.next(false));

    return functionPromise;
  }

  public async handleCheckoutSession(sessionSnapShot: DocumentSnapshot) {
    const { sessionId } = sessionSnapShot.data() ?? {};

    if (sessionId) {
      // We have a session, let's redirect to Checkout
      const stripe = (await loadStripe(
        this.environment.stripePublishableKey
      )) as Stripe;

      stripe.redirectToCheckout({ sessionId }).then((res) => {
        if (res.error) {
          console.log(res.error.message);
        } else {
          this.facebookWebEventsService.logEvent('Subscribe');
        }
      });

      this.processing.next(false);
    } else {
      // No session, let's show the user the error message?
      // This actually returns multiple subscriptions results..the first one(s) don't have a the sessionId
      // TODO: figure out how to detect an error
    }
  }

  private async initializeAvailablePlans(
    product: QueryDocumentSnapshot<unknown>
  ) {
    const productPricesPath = `products/${product.id}/prices`;
    const priceSnap = collection(this.firestore, productPricesPath);
    const pricesQuery = query(priceSnap, orderBy('unit_amount'));
    const pricesList = await getDocs(pricesQuery);
    const productData = product.data() as StripeProduct;
    const planName = productData.name;
    this.productList.push(productData);

    // Prices dropdown
    const mostExpensivePlan = pricesList.docs.reduce<
      QueryDocumentSnapshot<DocumentData>[]
    >(
      (mostExpensive, priceItem) =>
        (mostExpensive[0]?.data() as StripePrice)?.unit_amount >
        (priceItem.data() as StripePrice).unit_amount
          ? mostExpensive
          : [priceItem],
      [] as QueryDocumentSnapshot<DocumentData>[]
    );

    const updatedPlans = mostExpensivePlan.map((doc) => {
      const priceId = doc.id;
      const priceData = doc.data() as StripePrice;
      const priceInDollars = priceData.unit_amount / 100;
      const dollarAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: priceData.currency,
      }).format(priceInDollars);
      const formattedPrice = `${planName}: ${dollarAmount} per ${priceData.recurring.interval}`;
      const option: PlanOption = {
        id: priceId,
        name: formattedPrice,
        value: priceId,
      };

      return option;
    });

    this.plans.next([...updatedPlans, ...this.plans.value]);
  }

  private async getUserSubscriptions() {
    const currentUser: User = (await this.firebaseService.auth
      .currentUser) as User;

    const subscriptionRef = collection(
      this.firestore,
      'customers',
      currentUser?.uid,
      'subscriptions'
    );
    const subscriptionQuery = query(
      subscriptionRef,
      where('status', 'in', ['trialing', 'active'])
    );
    // Show subscription products
    onSnapshot(subscriptionQuery, (subscriptionSnap) => {
      if (subscriptionSnap.empty || subscriptionSnap.docs.length === 0) {
        this.showSubscribe.next(true);
      } else {
        this.checkSubscription(subscriptionSnap);
      }
    });
  }

  private async checkSubscription(
    subscriptionSnap: QuerySnapshot<DocumentData>
  ) {
    // Show Existing Subscription
    // In this implementation we only expect one Subscription to exist
    const subscription = subscriptionSnap.docs[0].data();
    const priceData = (await subscription['price'].get()).data();
    const productData = (await subscription['product'].get()).data();
    const amount: number = priceData['unit_amount'];
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: priceData.currency,
    }).format(amount / 100);

    this.$activePlanDescription.next(
      `You are paying ${formattedAmount} per ${priceData.interval} for the ${productData.name}.`
    );
  }
  // Checkout handler
  async subscribeToPlan(
    event: MouseEvent,
    paymentData: Record<string, string>
  ) {
    event.preventDefault();
    this.processing.next(true);
    // TODO: add a timeout handler
    const currentUserId =
      (await this.firebaseService.auth.currentUser)?.uid || '';
    const checkoutSessionsRef = collection(
      this.firestore,
      'customers',
      currentUserId,
      'checkout_sessions'
    );

    addDoc(checkoutSessionsRef, {
      price: paymentData['price'],
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    }).then((addedSession) => {
      // Wait for the CheckoutSession to get attached by the extension
      onSnapshot(addedSession, this.handleCheckoutSession.bind(this));
    });
  }
}
