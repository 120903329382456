import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import {
  FieldControlFields,
  FieldConfig,
  FieldSubControl,
} from '@smartflip/data-models';
import { bindValidations } from './bind-validators';

export const fieldToFormArray = (
  currentField: FieldConfig,
  formBuilder: UntypedFormBuilder
): UntypedFormArray => {
  const subGroups: UntypedFormGroup[] = [];

  if (!currentField.value && currentField.subControls) {
    // Create first blank item in the array
    currentField.value = currentField.subControls.map((subControl) => ({
      ...subControl,
      value: '',
    }));
  }

  currentField.value.forEach((fieldConfig: FieldConfig) => {
    const subGroup = formBuilder.group({});
    const { subControls } = currentField;

    subControls &&
      subControls.forEach((subField: FieldSubControl) => {
        const subControl = formBuilder.control(
          {
            value: fieldConfig[subField.name as FieldControlFields] || null,
            disabled: subField.disabled || false,
          },
          bindValidations(subField.validations || [])
        );
        subGroup.addControl(subField.name, subControl);
      });
    subGroups.push(subGroup);
  });

  return formBuilder.array(subGroups);
};
