import { Injectable } from '@angular/core';
import {
  checkoutPayload,
  conversionApiVersion,
  defaultPayload,
  facebookEventAccessToken,
  schedulePayload,
  startTrialPayload,
  viewContentPayload,
  webPixelID,
} from './facebook-web.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FacebookWebEventsService {
  constructor(private http: HttpClient) {}

  createEventPayload(eventType: string) {
    let updatedPayload = {};

    switch (eventType) {
      case 'StartTrial':
        updatedPayload = {
          ...startTrialPayload,
          event_id: `StartTrial-${+new Date()}`,
          custom_data: {},
        };
        break;
      case 'Subscribe':
        updatedPayload = {
          ...defaultPayload,
          event_id: `Subscribe-${+new Date()}`,
          custom_data: {},
        };
        break;
      case 'ViewContent':
        updatedPayload = {
          ...viewContentPayload,
          event_id: `ViewContent-${+new Date()}`,
          custom_data: {},
        };
        break;
      case 'Schedule':
        updatedPayload = {
          ...schedulePayload,
          event_id: `Schedule-${+new Date()}`,
          custom_data: {},
        };
        break;
      case 'InitiateCheckout':
        updatedPayload = checkoutPayload;
        break;
      default:
        return {};
    }

    return updatedPayload;
  }

  logEvent(eventType: string) {
    const baseUrl = 'https://graph.facebook.com';
    const url = `${baseUrl}/${conversionApiVersion}/${webPixelID}/events?access_token=${facebookEventAccessToken}`;
    const payload = this.createEventPayload(eventType);
    console.log('Event payload: ', payload);

    window['fbq']('track', eventType, payload);
  }
}
