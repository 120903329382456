import { Component } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
  field: FieldConfig;
  group: UntypedFormGroup;
}
