export const hiddenList = [
  'name',
  'beds',
  'baths',
  'propertyType',
  'year',
  'sqft',
  'lot',
  'geoLocation',
];

export interface AddressFormBaseValue {
  address: string;
  city: string;
  state: string;
  zip: string;
}
