import { Injectable } from '@angular/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  AuthCredential,
  AuthError,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
} from '@angular/fire/auth';
import { FirebaseService } from '@thesmartflip/data-firebase';

@Injectable({
  providedIn: 'root',
})
export class NativeLoginService {
  constructor(private firebaseService: FirebaseService) {}

  async nativeGoogleLogin(): Promise<any> {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token

    return GoogleAuthProvider.credential(result.credential?.idToken);
  }

  async nativeFacebookLogin(): Promise<any> {
    const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos'];
    const result = await FirebaseAuthentication.signInWithFacebook({
      scopes: FACEBOOK_PERMISSIONS,
      skipNativeAuth: true,
    });

    return FacebookAuthProvider.credential(
      result.credential?.accessToken || ''
    );
  }

  async nativeAppleLogin() {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');

    return provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
  }

  async completeLogin(authCredential: AuthCredential) {
    // Make Firebase aware of the login
    return signInWithCredential(this.firebaseService.auth, authCredential)
      .then(() => {
        return this.firebaseService.auth.currentUser;
      })
      .catch((e: AuthError) => {
        //TODO: Check for same creds error
        alert('Error with SignInCredentials: \n' + JSON.stringify(e));
        return null;
      });
  }
}
