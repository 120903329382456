import { ModuleWithProviders, NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FeaturesLoadingIndicatorModule } from '@smartflip/loading-indicator';
import { StripePaymentFormComponent } from './stripe-payment-form/stripe-payment-form.component';
import { featureStripePaymentRoutes } from './lib.routes';
import { SubscriptionFormComponent } from './subscription-form/subscription-form.component';
import { StripePaymentService } from './stripe-payment.service';

const cloudRegion = isDevMode() ? 'us-east4' : 'us-central1';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FeaturesLoadingIndicatorModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule.forChild(featureStripePaymentRoutes),
    ReactiveFormsModule,
  ],
  declarations: [StripePaymentFormComponent, SubscriptionFormComponent],
})
export class StripePaymentModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: StripePaymentModule,
      providers: [
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment,
        },
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFunctions(() => getFunctions(undefined, cloudRegion)),
        StripePaymentService,
      ],
    };
  }
}
