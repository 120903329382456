import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  field: FieldConfig;
  group: UntypedFormGroup;
  buttonType: string;

  handleClick(evt) {
    this.buttonClick.emit(evt);
  }

  ngOnInit() {
    this.buttonType = this.field.buttonType;
  }
}
