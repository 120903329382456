import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(private loadingCtrl: LoadingController) {}

  async showLoading(
    message: string = 'Working',
    duration: number = 0
  ): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingCtrl.create({
      message,
      duration,
    });

    loading.present();

    return loading;
  }
}
