<form
  class="dynamic-form"
  [formGroup]="sellingForm"
  (submit)="onSubmit($event)"
>
  <ng-container
    *ngFor="let field of objectKeys(fieldList)"
    dynamicField
    [field]="fieldList[field]"
    [group]="sellingForm"
  >
  </ng-container>
</form>
