export const tabSections = [
  {
    icon: 'article',
    label: 'Property Details',
    template: 'general',
    pendingOrder: 0,
    activeOrder: 2,
    soldOrder: 2,
  },
  {
    icon: 'monetization_on',
    label: 'Cost Details',
    template: 'costs',
    pendingOrder: 2,
    activeOrder: 0,
    soldOrder: 1,
  },
  {
    icon: 'analytics',
    label: 'Analysis',
    template: 'analytics',
    pendingOrder: 3,
    activeOrder: 1,
    soldOrder: 0,
  },
  {
    icon: 'find_in_page',
    label: 'Comps',
    template: 'comparables',
    pendingOrder: 1,
    activeOrder: 3,
    soldOrder: 3,
  },
];
