import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, updateDoc } from '@angular/fire/firestore';
import { Upload } from '@smartflip/data-models';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  basePath = 'uploads';
  private firebaseStorage: Storage = inject(Storage);
  private firebaseStore: Firestore = inject(Firestore);

  saveFileData(upload: Upload) {
    const property = doc(this.firebaseStore, `Properties/${upload.propertyId}`);

    updateDoc(property, { imgUrl: upload.url });
  }

  async pushUpload(upload: Upload): Promise<boolean> {
    const { propertyId } = upload;

    if (propertyId) {
      const storageRef = this.firebaseStorage.ref(
        `${this.basePath}/${propertyId}/${upload.name}`
      );
      await storageRef.put(upload.file);
      const url = await storageRef.getDownloadURL().toPromise();
      upload.url = url;
      upload.name = upload.file.name;
      this.saveFileData(upload);
      return url;
    }

    return false;
  }
}
