import { Injectable, Output, EventEmitter, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { debounceTime } from 'rxjs/operators';
import {
  Calculation,
  CalculationField,
  FieldConfig,
  FormGroupFields,
  FormGroupObject,
  Property,
  PropertyField,
  PropertySection,
} from '@smartflip/data-models';
import { specialSections } from '@smartflip/data-constants';
import {
  generalFields,
  ownerFields,
  mortgageFields,
  summaryFields,
  acquisitionFields,
  carryFields,
  repairFields,
  repairDetailFields,
  sellingFields,
  otherFields,
} from '@smartflip/data-field-configuration';
import { FormService } from './form.service';
import { calculateSellingCosts } from './calculations/calculate.selling-costs';

@Injectable({
  providedIn: 'root',
})
export class RoiService {
  private firestore: Firestore = inject(Firestore);
  currentProperty: Property | undefined;
  formGroups: FormGroupObject = new FormGroupObject(); // needs to be defined as SOMEthing;
  calculationValues: Calculation = new Calculation();

  // All of the forms are subscribed to this event
  @Output() formsUpdated$: EventEmitter<string> = new EventEmitter();
  // @Output() $loanDataUpdated: EventEmitter<{}> = new EventEmitter();

  getNum(stringVal: string) {
    return !stringVal || stringVal.length === 0
      ? 0
      : parseFloat(stringVal.replace('$', ''));
  }

  showCurrency(num: number) {
    return typeof num !== 'number' ? 0 : this.cp.transform(num);
  }

  createDataset() {
    // make every form value available to the service
    this.addFields(generalFields, 'gen');
    this.addFields(ownerFields, 'owner');
    this.addFields(mortgageFields, 'mortgage');
    this.addFields(summaryFields, 'summary');
    // this.addFields(loanFields, 'loan');
    this.addFields(acquisitionFields, 'acq');
    this.addFields(carryFields, 'carry');
    this.addFields(repairFields, 'repair');
    this.addFields(repairDetailFields, 'repairdetail');
    this.addFields(sellingFields, 'selling');
    this.addFields(otherFields, 'other');
    this.calculate();
  }

  addFields(fieldArray: FieldConfig[], sectionName: any) {
    // create values in this service to match the form fields
    this.calculationValues[sectionName as CalculationField] = {} as any;
    const tmpArray = []; // clone it to prevent changess to source

    for (const field of fieldArray) {
      if (field.name !== 'images') {
        const section: PropertySection =
          this.currentProperty?.[sectionName as PropertyField] || '';
        const fieldName = field.name || '';
        const sectionValue = section[fieldName];
        // TODO: confirm that it is ok to accept 0 values. This was preventing them before
        const existingValue =
          section !== null && sectionValue !== undefined ? sectionValue : null;
        // TODO: validate the redundancy here
        let fieldValue =
          existingValue === false || existingValue || existingValue === 0
            ? existingValue
            : field.value || null; // from the db, default or just null
        // TODO: confirm this...if it's seconds, not milliseconds, we don't need the 1000
        if (field.type === 'date' && fieldValue?.seconds) {
          fieldValue = new Date(fieldValue.seconds * 1000);
        }
        const newField = { ...field };
        newField.value = fieldValue;
        this.calculationValues[sectionName as CalculationField][field.name] =
          newField;
        tmpArray.push(newField);
      }
    }
    this.formGroups[sectionName] = this.formService.createControl(tmpArray);
  }
  /**
   * @description ensure the calculation values are all populated
   * @param formGroup
   * @param sectionName
   */
  updateService(formGroup: UntypedFormGroup, sectionName: string) {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const val = formGroup.controls[controlName].value;
      const calcValue = controlName === 'loan' ? this.getNum(val) : val;
      this.calculationValues[sectionName][controlName].value = calcValue;
    });
  }

  getSummaryTotals() {
    // CARYY COSTS
    const calcVals = this.calculationValues;
    let formVals = this.formGroups.carry.getRawValue();
    let total = 0;
    const duration = formVals.duration;
    for (const val of Object.keys(formVals)) {
      if (val !== 'duration') {
        total += parseFloat(formVals[val] || 0) * duration;
      }
    }

    calcVals.totalCarryCosts = total;
    // reset to 0 so that our running totals work
    const updatedData = {
      'loan-points-fees': 0,
      'subtotal-fees': 0,
      'subtotal-base': 0,
      'subtotal-other': 0,
    };
    total = 0;
    const loanPoints = calcVals.summary['loanpoints'].value || 0;
    const loanValue = calcVals.summary['loan'].value || 0;

    updatedData['loan-points-fees'] = loanPoints * loanValue;
    // ACQUISITION COSTS
    formVals = this.formGroups.acq.getRawValue();
    for (const val of Object.keys(formVals)) {
      if (!val.includes('subtotal')) {
        // don't include the subtotals
        total += parseFloat(formVals[val] || 0);
        const subType = val.includes('fees')
          ? 'fees'
          : val.includes('base')
          ? 'base'
          : val.includes('other')
          ? 'other'
          : 'none';

        if (subType !== 'none') {
          updatedData['subtotal-' + subType] += parseFloat(formVals[val] || 0);
        }
      }
    }

    this.formGroups.acq.patchValue(updatedData, { emitEvent: false });
    calcVals.totalAcquisitionCosts = total;

    // REPAIR COSTS
    formVals = this.formGroups.repair.value;
    total = 0;
    for (const val of Object.keys(formVals)) {
      total +=
        typeof formVals[val] === 'boolean' ? 0 : parseFloat(formVals[val] || 0);
    }

    calcVals.totalRepairCosts = total;

    // SELLING COSTS

    calcVals.totalSellCosts = calculateSellingCosts(
      calcVals,
      this.formGroups.selling
    );

    // OTHER COSTS
    formVals = this.formGroups.other.value;
    total = 0;
    for (const val of Object.keys(formVals)) {
      total += parseFloat(formVals[val] || 0);
    }
    calcVals.totalOtherCosts = total;
  }

  // Do the math
  calculate() {
    const vals = this.calculationValues;
    const purchasePrice = parseFloat(vals.summary['price'].value);
    const sellingPrice: number = parseFloat(vals.summary['sell'].value);
    const rehab = vals.totalRepairCosts; // parseFloat(vals.summary['rehab'].value);
    const useRepairInLoan = this.formGroups.repair.get('includeInLoan')?.value;
    const basecosts = purchasePrice + (useRepairInLoan ? rehab : 0);
    vals.summary['loan'].value = basecosts;
    const loanVal = this.showCurrency(basecosts);
    const loanToValue = ((basecosts / sellingPrice) * 100).toFixed(2) + '%';

    // Carrying Costs
    const loanInterest = (vals.carry['interest'].value = (
      ((vals.summary['loanrate'].value / 100) * purchasePrice) /
      12
    ).toFixed(2)); // monthly interest
    const propertyTaxes = (vals.carry['proptax'].value =
      vals.summary['tax'].value / 12); // monthly taxes

    // Acquisition Costs
    const loanPointsFees = purchasePrice * vals.summary['loanpoints'].value;

    vals.summary['loantovalue'].value = loanToValue;
    this.formGroups.summary.patchValue(
      {
        loan: loanVal,
        loantovalue: loanToValue,
      },
      { emitEvent: false }
    );
    vals.carry['interest'].value = loanInterest;
    vals.carry['proptax'].value = propertyTaxes;
    this.formGroups.carry.patchValue(
      {
        interest: loanInterest,
        proptax: propertyTaxes,
      },
      { emitEvent: false }
    );
    vals.acq['loan-points-fees'].value = loanPointsFees;
    this.formGroups.acq.patchValue(
      {
        'loan-points-fees': loanPointsFees,
      },
      { emitEvent: false }
    );

    // Get all totals
    this.getSummaryTotals();
    const {
      totalAcquisitionCosts,
      totalCarryCosts,
      totalOtherCosts,
      totalRepairCosts,
      totalSellCosts,
    } = vals;
    const othercosts =
      totalAcquisitionCosts +
      totalCarryCosts +
      totalOtherCosts +
      totalRepairCosts +
      totalSellCosts;

    setTimeout(() => {
      const allcosts = basecosts + othercosts - rehab; // Repair cost is in both base and other so remove it once here
      vals.allBudgetCosts = othercosts;
      vals.profit = sellingPrice - allcosts;
    }, 0);
  }

  handleFormChanges(formName: FormGroupFields) {
    // owner and mortgage data does not require calculation
    if (!specialSections.includes(formName)) {
      this.updateService(this.formGroups[formName], formName); // give this service updated calculation values
      this.calculate();
    }
    // Will trigger property Service update property
    // and update profit details
    this.formsUpdated$.emit(formName);
  }

  async init(propertyId: string) {
    this.calculationValues = new Calculation();
    const formList: FormGroupFields[] = [
      'gen',
      'owner',
      'mortgage',
      'summary',
      'acq',
      'carry',
      'repair',
      'selling',
      /* 'repairdetail' ,*/ 'other',
    ];
    const propertyDocument = doc(this.firestore, `Properties/${propertyId}`);
    const documentSnap = await getDoc(propertyDocument);

    this.currentProperty = documentSnap?.data() as Property;
    this.createDataset(); // Watch the forms for input changes

    formList.forEach((formName: FormGroupFields) => {
      this.formGroups[formName].valueChanges
        .pipe(debounceTime(800))
        .subscribe(() => this.handleFormChanges(formName));
    });
  }

  constructor(private cp: CurrencyPipe, private formService: FormService) {}
}
