import { DatafinitiProperty, Transaction } from '@smartflip/data-models';
import { DatafinitiExtraInfo } from './datafiniti.models';
import { SQUARE_FEET_PER_ACRE } from '@smartflip/data-constants';

export const getLastSoldTransaction = (transactions: Transaction[]) =>
  transactions
    .filter((transaction) => !!transaction.saleDate)
    .sort(
      (a, b) =>
        +new Date(b.saleDate as string) - +new Date(a.saleDate as string)
    );

export const convertPropertyToAddedInfo = (
  property: DatafinitiProperty
): DatafinitiExtraInfo => {
  const {
    id,
    yearBuilt,
    lotSizeValue,
    lotSizeUnit,
    floorSizeValue,
    floorSizeUnit,
    numBedroom,
    numBathroom,
    imageURLs,
    geoLocation,
    propertyType,
  } = property;

  const sqft = floorSizeValue?.toString();
  const lot = convertAcresToSqFt(
    lotSizeValue || 0,
    lotSizeUnit || ''
  )?.toString();
  const addedInfo = {
    year: yearBuilt?.toString() || '',
    sqft: sqft || '',
    lot: lot || '',
    datafinitiId: id || '',
    beds: numBedroom || 0,
    baths: numBathroom || 0,
    images: imageURLs || [],
    geoLocation: geoLocation || '',
    propertyType: propertyType || '',
  };

  return addedInfo;
};

const convertAcresToSqFt = (lotSize: number, lotUnits: string) =>
  lotUnits.toLowerCase().includes('ft')
    ? lotSize
    : Math.round(lotSize * SQUARE_FEET_PER_ACRE);
