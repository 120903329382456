import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerService } from './customer.service';

@NgModule({
  imports: [CommonModule],
})
export class ServicesHelperUtilsModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: ServicesHelperUtilsModule,
      providers: [
        CustomerService,
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
