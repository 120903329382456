import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RoiService } from '@smartflip/ui-utils';
import { FieldConfig } from '@smartflip/data-models';
import { PropertyService } from '@smartflip/helper-utils';
import { FirebaseAnalyticsService } from '@thesmartflip/data-firebase';
import { ComparablesService } from '../../shared/services/comparables.service';
import { PropertyDetailsService } from './property-details.service';
import { tabSections } from './property-details.constants';
import { sortTabsByStatus } from './property-details.helper';

@Component({
  selector: 'app-calculation',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
})
export class PropertyDetailsComponent implements OnInit {
  totalcost: FieldConfig;
  profit: FieldConfig;
  propertyId: string;
  private propertyIsSet: BehaviorSubject<boolean> = new BehaviorSubject(false); // Property has enough information to pull comps
  public propertyIsSet$ = this.propertyIsSet.asObservable();
  public compError: boolean;
  public routeLoaded: boolean;
  public tabSections;
  public showComps = false;

  handleTabChange(newTabIndex: number) {
    const tabName = this.tabSections[newTabIndex].label || 'General';

    this.showComps = tabName === 'Comps';
    this.checkProperty();
    this.firebaseAnalyticsService.logEvent({
      name: 'screen_view',
      params: {
        screen_name: tabName,
      },
    });
  }

  checkProperty() {
    const { address, zip } = this.roi.formGroups.gen.value;
    const idExists =
      this.propServ.propertyId && this.propServ.propertyId?.length > 0;
    const addressIsSet = address?.length > 0;
    const zipCodeExists = zip?.length > 0;
    const propertySetCheck = idExists && addressIsSet && zipCodeExists;

    this.propertyDetailsService.setProperty(propertySetCheck);
    this.compError = false; // reset this every time
    //TODO: compError should only be reset if address details are changed.
  }

  async init() {
    await this.roi.init(this.propertyId); // reset the ROI service every time we reach this view
    this.routeLoaded = true;
    this.propertyDetailsService.propertyIsSet$.subscribe((propSetVal) =>
      this.propertyIsSet.next(propSetVal),
    );
  }

  constructor(
    private route: ActivatedRoute,
    private propServ: PropertyService,
    private compServ: ComparablesService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    public roi: RoiService,
    private propertyDetailsService: PropertyDetailsService,
  ) {}

  ngOnInit() {
    this.compServ.reset(); // clear any existing comps
    this.compError = false;
    this.route.params.subscribe((parameters) => {
      this.propertyId = parameters.id;
      this.propServ.setCurrentProperty(this.propertyId).then(() => {
        const { solddate, purchasedate } = this.propServ.currentProperty.gen;
        const status = solddate ? 'sold' : purchasedate ? 'active' : 'pending';

        this.tabSections = tabSections.sort(sortTabsByStatus(status));
        this.init();
      });
    });
  }
}
