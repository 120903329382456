export const carryFields = [
  {
    type: 'input',
    label: 'Loan Duration',
    name: 'duration',
    inputType: 'text',
    value: 6,
  },
  {
    type: 'input',
    label: 'Interest on Loan',
    inputType: 'number',
    name: 'interest',
    disabled: true,
  },
  {
    type: 'input',
    label: 'Property Taxes',
    inputType: 'text',
    name: 'proptax',
    disabled: true,
  },
  {
    type: 'input',
    label: 'Utilities',
    inputType: 'text',
    name: 'util',
  },
  {
    type: 'input',
    label: 'Insurance',
    inputType: 'text',
    name: 'insurance',
  },
  {
    type: 'input',
    label: 'Property Maintenance',
    inputType: 'text',
    name: 'maintenance',
  },
  {
    type: 'input',
    label: 'Assoc. Fees',
    inputType: 'text',
    name: 'fees',
  },
  {
    type: 'input',
    label: 'Other Carrying  Costs',
    inputType: 'text',
    name: 'other',
  },
];
