import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { Comparable, dropdownOption } from '@smartflip/data-models';
import { RoiService } from '@smartflip/ui-utils';
import { generateEstimatedARV } from '@smartflip/utils-data';
import { arvUpdatedMessage } from '../comparables.constants';

@Component({
  selector: 'smartflip-comparables-filter-actions',
  templateUrl: './comparables-filter-actions.component.html',
  styleUrls: ['./comparables-filter-actions.component.scss'],
})
export class ComparablesFilterActionsComponent {
  @Input() arvButtonText: string;
  @Input() pullCompsButtonText: string;
  @Input() selectedComps: Comparable[] = [];
  @Input() filterOptions: dropdownOption[] = [];

  @Output() pullCompsClicked: EventEmitter<any> = new EventEmitter();

  public ARV$ = new BehaviorSubject<number>(
    this.roi.calculationValues.summary.sell?.value,
  );

  constructor(
    private _snackBar: MatSnackBar,
    private roi: RoiService,
  ) {}

  public pullComparables() {
    this.pullCompsClicked.emit();
  }

  public generateARV(): void {
    if (this.selectedComps?.length === 0) return;

    const ARV = generateEstimatedARV(this.selectedComps);
    const duration = 30000; // 30 seconds
    const sell = Math.round(ARV / 1000) * 1000;

    this.roi.formGroups.summary.patchValue({ sell });
    this._snackBar.open(arvUpdatedMessage, null, {
      duration,
      panelClass: 'green-snackbar',
    });
    this.ARV$.next(ARV);
  }
}
