import { FieldConfig } from '@smartflip/data-models';
import { Validators } from '@angular/forms';
import { PROFILE_DEFAULTS, propertyTypeList } from '@smartflip/data-constants';

export const generalFields: FieldConfig[] = [
  {
    type: 'input',
    label: 'Property Address',
    inputType: 'text',
    name: 'address',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Address Required',
      },
    ],
  },
  {
    type: 'input',
    label: 'City',
    inputType: 'text',
    name: 'city',
    class: 'city-input',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'City is Required',
      },
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z ]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: 'State',
    inputType: 'text',
    name: 'state',
    value: PROFILE_DEFAULTS.state,
    class: 'state-input',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'State is Required',
      },
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: 'Zip',
    inputType: 'text',
    name: 'zip',
    class: 'zip-input',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Zip is Required',
      },
      {
        name: 'pattern',
        validator: Validators.pattern('^[0-9]+$'),
        message: 'Please enter only numbers',
      },
      {
        name: 'minLength',
        validator: Validators.minLength(5),
        message: 'Please 5 digits for Zip',
      },
      {
        name: 'maxLength',
        validator: Validators.maxLength(5),
        message: 'Please 5 digits for Zip',
      },
    ],
  },
  {
    type: 'input',
    label: 'Year Built',
    name: 'year',
    value: null,
    class: '',
  },
  {
    type: 'input',
    label: 'Interior Size (sqft)',
    name: 'sqft',
    value: null,
    class: '',
  },
  {
    type: 'input',
    label: 'Lot Size (sqft)',
    name: 'lot',
    value: null,
    class: '',
  },
  {
    type: 'input',
    label: 'No. Bedrooms',
    name: 'beds',
    value: null,
    class: '',
  },
  {
    type: 'input',
    label: 'No. Bathrooms',
    name: 'baths',
    value: null,
    class: '',
  },
  {
    type: 'select',
    label: 'Property Type',
    name: 'propertyType',
    value: null,
    multiple: false,
    options: propertyTypeList,
  },
  {
    type: 'input',
    label: "Seller's name",
    inputType: 'text',
    name: 'name',
    class: 'sellerName',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'date',
    label: 'Purchase Date',
    name: 'purchasedate',
    value: null,
    class: 'date-field',
  },
  {
    type: 'date',
    label: 'Date Sold',
    name: 'solddate',
    value: null,
    class: 'date-field',
  },
  {
    type: 'input',
    label: '',
    name: 'geoLocation',
    value: '',
    class: 'hidden-field',
  },
];

export const ownerFields: FieldConfig[] = [
  {
    type: 'input',
    label: "Owner's First Name",
    inputType: 'text',
    name: 'first',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: "Owner's Last Name",
    inputType: 'text',
    name: 'last',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: 'Mailing Address',
    inputType: 'text',
    name: 'address',
    class: 'mailing-input',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  // {
  //   type: 'input',
  //   label: 'Current Mortgage',
  //   inputType: 'number',
  //   name: 'mortgageAmount',
  // },
];

export const mortgageFields: FieldConfig[] = [
  {
    type: 'input',
    label: 'Lender',
    inputType: 'text',
    name: 'lender',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: 'Title Company',
    inputType: 'text',
    name: 'titleCompany',
    validations: [
      {
        name: 'pattern',
        validator: Validators.pattern('^[a-zA-Z .-]+$'),
        message: 'Please enter only text',
      },
    ],
  },
  {
    type: 'input',
    label: 'Mortgage Amount',
    inputType: 'number',
    name: 'amount',
  },
];
