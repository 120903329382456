import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Comparable } from '@smartflip/data-models';
import { ComparablesHelperService } from './comparables-helper.service';

export interface MapLocation {
  latitude: number;
  longitude: number;
  label: string;
  content: Node | google.maps.marker.PinElement;
  property?: Comparable;
}

@Component({
  selector: 'lib-interactive-map',
  templateUrl: './interactive-map.component.html',
  styleUrls: ['./interactive-map.component.scss'],
})
export class InteractiveMapComponent implements AfterViewInit {
  @Input() lat: number = 0;
  @Input() long: number = 0;
  @Input() startingLocationLabel = '';
  @Input() locations: MapLocation[] = [];
  @Input() highlightedLocation?: string;

  @Output() markerSelected = new EventEmitter<MapLocation | null>();

  hoveredProperty?: MapLocation | null;

  constructor(private comparablesHelperService: ComparablesHelperService) {
    // Handle activity on the map
    this.comparablesHelperService.markerHoverListener$.subscribe((marker) => {
      const property = this.locations.find(
        (location) => location.property?.street === marker,
      );

      if (property) {
        this.onMouseOver(property);
      } else {
        this.onMouseOut();
      }
    });
  }

  onMarkerSelected(marker: MapLocation | null) {
    this.markerSelected.emit(marker);
  }

  onMouseOver(marker: MapLocation) {
    this.hoveredProperty = marker;
    this.onMarkerSelected(marker);
  }

  onMouseOut() {
    this.hoveredProperty = null;
    this.onMarkerSelected(null);
  }

  ngAfterViewInit(): void {
    this.comparablesHelperService.initMap();
  }

  ngOnChanges(changes: { locations: { firstChange: any } }): void {
    if (changes.locations && !changes.locations.firstChange) {
      this.comparablesHelperService.initMap();
    }
  }
}
